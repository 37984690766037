import { action } from 'typesafe-actions';

import {
  ILoadFailure,
  ILoadRequest,
  ILoadSuccess,
  ILoadFailureAction,
  ILoadRequestAction,
  ILoadSuccessAction,
  SSOTypes
} from './types';

export const loadFailure = (payload: ILoadFailure): ILoadFailureAction =>
  action(SSOTypes.LOAD_FAILURE, payload);

export const loadRequest = (payload: ILoadRequest): ILoadRequestAction =>
  action(SSOTypes.LOAD_REQUEST, payload);

export const loadSuccess = (payload: ILoadSuccess): ILoadSuccessAction =>
  action(SSOTypes.LOAD_SUCCESS, payload);

export const SSOActions = {
  loadFailure,
  loadRequest,
  loadSuccess
};
