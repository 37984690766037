import React from 'react';
import { Switch } from 'react-router-dom';

import Wrapper from './Wrapper';

import Editor from '~/pages/Editor';
import Matrix from '~/pages/Matrix';
import ShowCase from '~/pages/ShowCase';

export default function Routes() {
  return (
    <Switch>
      <Wrapper path="/editor/:id" component={Editor} />
      <Wrapper path="/matrix" component={Matrix} />
      <Wrapper path="/showcase" component={ShowCase} />
    </Switch>
  );
}
