import React from 'react';
import { MdWarning, MdCheckCircle } from 'react-icons/md';
import { PulseLoader } from 'react-spinners';

import { ErrorWrapper, FloatingContainer, SuccessWrapper } from './styles';

import ThemeColors from '~/styles/theme-colors';

interface IProps {
  loading?: boolean;
  error?: string;
  success?: boolean;
  saving?: boolean;
  title?: string;
}

const MessageEditor = ({ loading, error, saving, success, title }: IProps) => {
  return (
    <FloatingContainer>
      {(loading || saving) && (
        <PulseLoader
          size={22}
          margin={10}
          color={ThemeColors.primaryColor[100]}
        />
      )}
      {error && (
        <ErrorWrapper>
          <MdWarning color={ThemeColors.primaryColor[100]} size={80} />
          <h3 dangerouslySetInnerHTML={{ __html: error }} />
        </ErrorWrapper>
      )}
      {success && (
        <SuccessWrapper>
          <MdCheckCircle color="#43bb39" size={80} />
          <h3>{title || 'Página salva com sucesso!'}</h3>
        </SuccessWrapper>
      )}
    </FloatingContainer>
  );
};

export default MessageEditor;
