import { IApiResponse } from '~/models/api.model';
import { IComponent } from '~/models/component.model';
import api from '~/services/api';
import { ISSOState } from '~/store/ducks/sso/types';

class ComponentsService {
  public async save({
    sso,
    component
  }: {
    sso: ISSOState;
    component: IComponent;
  }): Promise<IApiResponse> {
    const response = await api.post(`/components`, component, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    if (response.status !== 200) {
      console.error(
        `[${response.status}] - ${
          response.data?.error?.message ?? 'Undefined Error'
        }`
      );
      return {
        error: {
          message: response.data?.error?.message ?? 'Undefined Error'
        }
      };
    }

    console.tron.log('saved row:', response.data.key);

    return response.data;
  }

  public async update(
    name: string,
    idComponent: string,
    sso: ISSOState
  ): Promise<boolean> {
    const response = await api.patch(
      `/components/${idComponent}`,
      { name },
      {
        headers: {
          token: sso.token,
          refresh_token: sso.refreshToken
        }
      }
    );

    if (response.status !== 200) {
      console.tron.error(
        `[${response.status}] ${response.data?.error?.message ?? ''}`
      );
      return false;
    }

    return true;
  }

  public async delete(idComponent: string, sso: ISSOState): Promise<boolean> {
    const response = await api.delete(`/components/${idComponent}`, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    if (response.status !== 200) {
      console.tron.error(
        `[${response.status}] ${response.data?.error?.message ?? ''}`
      );
      return false;
    }

    return true;
  }
}

export default new ComponentsService();
