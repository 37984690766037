import { DialogContent as MuiDialogContent } from '@material-ui/core';
import styled from 'styled-components';

export const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;

  padding: 25px 25px !important;
  max-width: 450px;

  .title {
    margin: 0px;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-weight: 500;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.6;
  }

  .input {
    margin-bottom: 16px;
    border-radius: 16px;

    margin-top: 20px;
    margin-bottom: 15px;

    width: 400px;
  }

  .input fieldset {
    border-radius: 12px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: right;

    margin-top: 10px;
  }

  .button {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 8px 22px;
    color: rgb(255, 255, 255);
    background-color: rgb(221, 35, 91);
    border-radius: 20px;
    text-transform: none;
    box-shadow: none;
    border: none;

    &:hover {
      background-color: rgb(170, 34, 75);
    }
  }
`;
