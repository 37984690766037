/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import {
  FieldBox,
  FieldGroup,
  Select,
  TextField,
  TextArea,
  Label
} from './styles';

export interface IFieldContent {
  key: string;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  cols?: number;
  value?: string;
  options?: string[];
  removeFromLayout?: boolean;
  canBeRemovedFromLayout: boolean;
}

interface IFieldData {
  content: IFieldContent;
}

const MatrixField = ({ content }: IFieldData) => (
  <FieldBox id={content.key}>
    <Label value={content.label} tabIndex={-1} />
    {content.type === 'textarea' && (
      <TextArea value={content.placeholder} cols={content.cols} tabIndex={-1} />
    )}
    {content.type === 'checkbox' && (
      <FieldGroup>
        {content.options?.map((opt, index) => (
          <label key={`${content.key}_${index}`}>
            <input type="checkbox" name={content.key} value={opt} />
            {opt}
          </label>
        ))}
      </FieldGroup>
    )}
    {content.type === 'radio' && (
      <FieldGroup>
        {content.options?.map((opt, index) => (
          <label key={`${content.key}_${index}`}>
            <input type="radio" name={content.key} value={opt} />
            {opt}
          </label>
        ))}
      </FieldGroup>
    )}
    {content.type === 'select' && (
      <Select tabIndex={-1}>
        {content.options?.map((opt, index) => (
          <option key={`${content.key}_${index}`} value={opt}>
            {opt}
          </option>
        ))}
      </Select>
    )}
    {content.type !== 'textarea' &&
      content.type !== 'checkbox' &&
      content.type !== 'radio' &&
      content.type !== 'select' && (
        <TextField value={content.placeholder} tabIndex={-1} />
      )}
  </FieldBox>
);

export default MatrixField;
