/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { PulseLoader } from 'react-spinners';

import Bee, { OnChangeResponse } from '@mailupinc/bee-plugin';

import ptBr from '../../resources/languages/pt-br.json';
import MessageEditor from '../MessageEditor';
import SaveComponentDialog from './SaveComponent';
import useRowComponent from './SaveComponent/hooks/useRowComponent';
import useSaveRowDialog from './SaveComponent/hooks/useSaveRowDialog';
import { Container, ErrorWrapper, LoadingWrapper } from './styles';
import Toolbar from './Toolbar';

import { useFormSelector } from '~/components/FormSelector';
import BeeConfig from '~/config/BeeFreePlugin';
import { IExternalContent } from '~/models/beefree.model';
import { IPage } from '~/models/page.model';
import fonts from '~/resources/fonts/google.json';
import ThemeColors from '~/styles/theme-colors';

interface IProps {
  page: IPage;
  template: unknown;
  showCheckout: boolean;
  onChange?: (json: string, response: OnChangeResponse) => void;
  onCleanTemplate: () => void;
  onError?: (errorMessage: string) => void;
  onLoad?: (json: string) => void;
  onSave?: (html: string, json: string, close: boolean) => void;
}

interface IResultsModal {
  success: boolean;
  name: string;
}

const BeePlugin = ({
  page,
  template,
  showCheckout,
  onChange,
  onError,
  onLoad,
  onSave,
  onCleanTemplate
}: IProps) => {
  const [bee, setBee] = useState<Bee>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingComponent, setSavingComponent] = useState(false);

  const [currentTemplate, setCurrentTemplate] = useState<string | null>(null);
  const closeRef = useRef<boolean>(true);
  const formSelector = useFormSelector();

  const { openModal, setClose, saveRowDialog } = useSaveRowDialog();
  const {
    deleteRowComponent,
    saveRowComponent,
    loadingRowComponents
  } = useRowComponent();

  let externalContentURLs: IExternalContent[] = [];
  const userExternalContentKey = `external-content:${page.owner}`;
  const userExternalContentData = localStorage.getItem(userExternalContentKey);
  if (userExternalContentData) {
    externalContentURLs = JSON.parse(userExternalContentData);
  }

  const handleLoad = useCallback(
    (json: string) => {
      setLoading(false);
      if (onLoad) {
        onLoad(json);
      }
    },
    [onLoad]
  );

  const apiURL = process.env.REACT_APP_API_URL;

  const handleSave = useCallback(
    (html: string, json: string) => {
      if (onSave) onSave(html, json, closeRef.current);
      setSaving(false);
    },
    [onSave]
  );

  useEffect(() => {
    if (savingComponent) {
      setTimeout(() => {
        setSavingComponent(false);
      }, 3000);
    }
  }, [savingComponent]);

  const handleToolbarTogglePreview = useCallback(() => {
    if (bee) bee.preview();
  }, [bee]);

  const handleToolbarToggleStructure = useCallback(() => {
    if (bee) bee.toggleStructure();
  }, [bee]);

  const handleToolbarSave = useCallback((mustClose: boolean) => {
    closeRef.current = mustClose;
    setSaving(true);
  }, []);

  const handleSaveRow = useCallback(
    async jsonFile => {
      const response = await saveRowComponent(jsonFile);

      setClose();

      if (!response)
        return setError(`Opsss...<br>
      Não foi possível salvar o bloco!<br>
      Feche a aba e tente novamente...`);

      return setSavingComponent(true);
    },
    [saveRowComponent, setClose]
  );

  const beeConfig: Bee.BeeConfig = {
    uid: `uid_${page.owner}`,
    container: 'bee-container',
    sidebarPosition: 'left',
    autosave: false,
    language: 'pt-BR',
    translations: ptBr,
    preventClose: false,
    trackChanges: true,
    saveRows: true,
    editorFonts: {
      showDefaultFonts: true,
      customFonts: [...fonts]
    },
    addOns: [
      {
        id: 'llCheckout',
        enabled: showCheckout
      }
    ],
    contentDialog: {
      saveRow: {
        label: 'Salvar dialog',
        handler: async (resolve, reject, args) => {
          const results = await openModal(args, 'Salvar');

          if (results && (results as IResultsModal).name) {
            resolve(results);
          } else {
            reject('');
          }
        }
      },
      onDeleteRow: {
        label: 'Deletar linha',
        handler: async (resolve, reject, args) => {
          const rows = await deleteRowComponent(args.row.metadata.id);

          if (rows) {
            resolve(true);
            setSavingComponent(true);
          } else {
            reject('');
            setError(`Opsss...<br>
            Não foi possível excluir o bloco!<br>
            Feche a aba e tente novamente...`);
          }
        }
      },
      manageForm: {
        label: 'Editar Formulário',
        handler: async (resolve, reject, args) => {
          formSelector
            .open(args, {
              id: page.owner,
              token: page.token
            })
            .then(structure => {
              if (structure) {
                resolve(structure);
              } else {
                reject();
              }
            })
            .catch(reason => {
              console.error(reason);
              reject();
            });
        }
      }
    },
    rowsConfiguration: {
      defaultRows: true,
      emptyRows: true,
      externalContentURLs: [
        ...externalContentURLs,
        {
          name: 'Meus blocos',
          value: `${apiURL}components/${page.owner}`,
          handle: 'saved-rows',
          behaviors: {
            canEdit: true,
            canDelete: true
          }
        }
      ]
    },
    onChange: (jsonFile, response) => {
      setCurrentTemplate(jsonFile);
      onChange!(jsonFile, response);
    },
    onSaveRow: async jsonFile => handleSaveRow(jsonFile),
    onError: errorMessage => onError!(errorMessage),
    onLoad: jsonFile => handleLoad(jsonFile),
    onSave: (jsonFile, htmlFile) => handleSave(htmlFile, jsonFile),
    onWarning: errorMessage => console.tron.warn(errorMessage)
  };

  useEffect(() => {
    if (saving && bee) {
      bee.save();
    }
  }, [bee, saving]);

  const setMenuPosition = (position: 'left' | 'right') => {
    if (bee) {
      beeConfig.sidebarPosition = position;
      bee.start(
        beeConfig,
        currentTemplate ? JSON.parse(currentTemplate) : template
      );
    }
  };

  useEffect(() => {
    async function load() {
      const beeInstance = new Bee();
      const token = await beeInstance.getToken(
        BeeConfig.clientId,
        BeeConfig.clientSecret
      );
      if (token?.non_field_errors) {
        console.error(token.non_field_errors);
        setError(`
          Opsss...<br>
          Não foi possível carregar o editor!<br>
          Feche a aba e tente novamente...`);
      } else {
        beeInstance.start(beeConfig, template);
        setBee(beeInstance);
      }
    }
    if (!bee) load();
  }, [bee, beeConfig, loading, template]);

  return (
    <>
      {!error && (
        <Container>
          {!loading && (
            <Toolbar
              onSave={handleToolbarSave}
              onCleanTemplate={onCleanTemplate}
              togglePreview={handleToolbarTogglePreview}
              toggleStructure={() => handleToolbarToggleStructure()}
              onChangeMenuPosition={direction => setMenuPosition(direction)}
            />
          )}
          <div id="bee-container" />
        </Container>
      )}
      {error && (
        <ErrorWrapper>
          <MdWarning color={ThemeColors.primaryColor[50]} size={80} />
          <h3 dangerouslySetInnerHTML={{ __html: error }} />
        </ErrorWrapper>
      )}
      {(saving || loadingRowComponents) && (
        <LoadingWrapper>
          <PulseLoader
            size={22}
            margin={10}
            color={ThemeColors.primaryColor[100]}
          />
        </LoadingWrapper>
      )}
      {savingComponent && (
        <LoadingWrapper>
          <MessageEditor
            title="Alterações salvas com sucesso!"
            success={savingComponent}
          />
        </LoadingWrapper>
      )}
      <SaveComponentDialog
        configDialog={saveRowDialog}
        isLoading={loadingRowComponents}
      />
    </>
  );
};

export default BeePlugin;
