/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import Cell from './Cell';
import { Container } from './styles';

import { IMatrixContent, Direction } from '~/models/matrix.model';

interface IMatrixProps {
  data: IMatrixContent[];
  layout: string[][];
  hasFields: boolean;
  handleAdd: (newLayout: string[][]) => void;
  handleChange: (updatedLayout: string[][], id?: string) => void;
}

const MatrixOrganizer = ({
  data,
  layout,
  hasFields,
  handleAdd,
  handleChange
}: IMatrixProps) => {
  const [areas, setAreas] = useState<string[]>([]);
  const [matrix, setMatrix] = useState<IMatrixContent[]>([]);

  const handleChangeFocus = (item: IMatrixContent): void => {
    setMatrix(currentMatrix =>
      currentMatrix.map(x => {
        x.focused = x.id === item.id;
        return x;
      })
    );
  };

  const handleLayoutAdd = (id: string, direction: Direction): void => {
    const clonedLayout: string[][] = JSON.parse(JSON.stringify(layout));
    const rowIndex = clonedLayout.findIndex(row => row.some(col => col === id));
    const colIndex = clonedLayout[rowIndex].findIndex(col => col === id);
    const rowSize = clonedLayout[rowIndex].length;
    const canShareRow = matrix.find(x => x.id === id)?.canShareRow ?? true;

    if (!hasFields) return;
    if (
      (direction === 'left' || direction === 'right') &&
      (rowSize === 3 || !canShareRow)
    ) {
      return;
    }

    switch (direction) {
      case 'up':
        clonedLayout.splice(rowIndex, 0, ['temp']);
        break;
      case 'down':
        clonedLayout.splice(rowIndex + 1, 0, ['temp']);
        break;
      case 'left':
        clonedLayout[rowIndex].splice(colIndex, 0, 'temp');
        break;
      case 'right':
        clonedLayout[rowIndex].splice(colIndex + 1, 0, 'temp');
        break;
      default:
        break;
    }

    handleAdd(clonedLayout);
  };

  const handleLayoutRemove = (
    layoutToUpdate: string[][],
    rowIndex: number,
    columnIndex: number
  ) => {
    layoutToUpdate[rowIndex].splice(columnIndex, 1);
    if (layoutToUpdate[rowIndex].length === 0) {
      layoutToUpdate.splice(rowIndex, 1);
    }
  };

  const handleMoveHorizontaly = (id: string, direction: Direction): void => {
    const clonedLayout: string[][] = JSON.parse(JSON.stringify(layout));
    const rowIndex = clonedLayout.findIndex(row => row.some(col => col === id));
    const colIndex = clonedLayout[rowIndex].findIndex(col => col === id);
    const rowSize = clonedLayout[rowIndex].length;

    // validations
    if (direction === 'left' && colIndex === 0) return;
    if (direction === 'right' && colIndex === rowSize - 1) return;

    const toColumn = colIndex + (direction === 'left' ? -1 : 1);
    const replaced = clonedLayout[rowIndex].splice(toColumn, 1, id);
    clonedLayout[rowIndex].splice(colIndex, 1, replaced[0]);

    const movedItem = matrix.find(x => x.id === id);
    if (movedItem) {
      handleChangeFocus(movedItem);
    }

    handleChange(clonedLayout);
  };

  const handleMoveVertically = (id: string, direction: Direction): void => {
    const clonedLayout: string[][] = JSON.parse(JSON.stringify(layout));
    const rowIndex = clonedLayout.findIndex(row => row.some(col => col === id));
    const colIndex = clonedLayout[rowIndex].findIndex(col => col === id);
    const rowSize = clonedLayout[rowIndex].length;
    const rows = clonedLayout.length;
    const toRow = rowIndex + (direction === 'up' ? -1 : 1);
    const toId = clonedLayout[toRow] ? clonedLayout[toRow][0] : '';
    const swapRow =
      !(matrix.find(x => x.id === id)?.canShareRow ?? true) ||
      (toId && !(matrix.find(x => x.id === toId)?.canShareRow ?? true));

    // validations
    if (direction === 'up' && rowIndex === 0 && rowSize === 1) return;
    if (direction === 'down' && rowIndex === rows - 1 && rowSize === 1) return;

    if (direction === 'up' && rowIndex === 0) {
      // remove item from the current position
      handleLayoutRemove(clonedLayout, rowIndex, colIndex);
      // add new row at the top of layout
      // and insert the item...
      clonedLayout.splice(0, 0, [id]);
    } else if (direction === 'down' && rowIndex === rows - 1) {
      // remove item from the current position
      handleLayoutRemove(clonedLayout, rowIndex, colIndex);
      // add new item in the last position
      clonedLayout.push([id]);
    } else if (swapRow) {
      const entireRow = clonedLayout[rowIndex];
      const replaced = clonedLayout.splice(toRow, 1, entireRow);
      clonedLayout.splice(rowIndex, 1, replaced[0]);
    } else {
      // find the right column to insert...
      let toColumn = 0;
      // if current row size is 1, insert in the middle
      if (rowSize === 1) toColumn = 1;
      // if current row size is 2, insert in the beginning or end
      if (rowSize === 2)
        toColumn = colIndex === 0 ? 0 : clonedLayout[toRow].length;
      // if current row size is 3, insert in the corresponding column index
      if (rowSize === 3)
        toColumn =
          clonedLayout[toRow].length < colIndex
            ? clonedLayout[toRow].length
            : colIndex;
      // determines if a swap is necessary (destination row is full)
      const swap = clonedLayout[toRow].length === 3;
      if (swap) {
        const replaced = clonedLayout[toRow].splice(toColumn, 1, id);
        clonedLayout[rowIndex].splice(colIndex, 1, replaced[0]);
      } else {
        clonedLayout[toRow].splice(toColumn, 0, id);
        handleLayoutRemove(clonedLayout, rowIndex, colIndex);
      }
    }

    const movedItem = matrix.find(x => x.id === id);
    if (movedItem) {
      handleChangeFocus(movedItem);
    }

    handleChange(clonedLayout);
  };

  const handleMove = (id: string, direction: Direction): void => {
    switch (direction) {
      case 'up':
      case 'down':
        handleMoveVertically(id, direction);
        break;
      case 'left':
      case 'right':
        handleMoveHorizontaly(id, direction);
        break;
      default:
        break;
    }
  };

  const handleRemove = (id: string): void => {
    const clonedLayout: string[][] = JSON.parse(JSON.stringify(layout));
    const rowIndex = clonedLayout.findIndex(row => row.some(col => col === id));
    const colIndex = clonedLayout[rowIndex].findIndex(col => col === id);
    const itemToRemove = matrix.find(x => x.id === id);

    if (!(itemToRemove?.canBeRemoved ?? true)) return;

    handleLayoutRemove(clonedLayout, rowIndex, colIndex);
    handleChange(clonedLayout, id);
  };

  const handleKeyBoardEvents = (
    id: string,
    event: React.KeyboardEvent<HTMLDivElement>
  ): boolean => {
    if (event.key === 'Tab') {
      const item = matrix.find(x => x.id === id);
      if (item) {
        const nextItem = matrix.find(
          y => y.index === (item.index ?? 0) + (event.shiftKey ? -1 : 1)
        );
        if (nextItem) {
          handleChangeFocus(nextItem);
        } else {
          const defaultItem = matrix.find(
            y => y.index === (event.shiftKey ? matrix.length : 1)
          );
          if (defaultItem) {
            handleChangeFocus(defaultItem);
          }
        }
      }
      event.preventDefault();
      return false;
    }

    if (event.shiftKey) {
      switch (event.key) {
        case 'ArrowUp':
          handleLayoutAdd(id, 'up');
          break;
        case 'ArrowDown':
          handleLayoutAdd(id, 'down');
          break;
        case 'ArrowLeft':
          handleLayoutAdd(id, 'left');
          break;
        case 'ArrowRight':
          handleLayoutAdd(id, 'right');
          break;
        case 'Delete':
          handleRemove(id);
          break;
        default:
          break;
      }
    } else if (event.target instanceof HTMLDivElement) {
      switch (event.key) {
        case 'ArrowUp':
          handleMove(id, 'up');
          break;
        case 'ArrowDown':
          handleMove(id, 'down');
          break;
        case 'ArrowLeft':
          handleMove(id, 'left');
          break;
        case 'ArrowRight':
          handleMove(id, 'right');
          break;
        default:
          break;
      }
    }

    return true;
  };

  useEffect(() => {
    const clonedLayout: string[][] = JSON.parse(JSON.stringify(layout));
    setAreas(
      clonedLayout.map(row =>
        row
          .map(col =>
            Array(6 / row.length)
              .fill(col)
              .join(' ')
          )
          .join(' ')
      )
    );
  }, [layout]);

  useEffect(() => {
    const flattenLayout = layout.flat();
    const newMatrix = data
      .filter(x => flattenLayout.some(l => l === x.id))
      .map(item => {
        // set initial positions
        item.rowPosition = [];
        item.columnPosition = [];
        // set row position
        const rowIndex = layout.findIndex(row =>
          row.some(col => col === item.id)
        );
        if (rowIndex === 0) {
          item.rowPosition.push('first');
        }
        if (rowIndex === layout.length - 1) {
          item.rowPosition.push('last');
        }
        // set column position
        const colIndex = layout[rowIndex].findIndex(col => col === item.id);
        if (colIndex === 0) {
          item.columnPosition.push('first');
        }
        if (colIndex === layout[rowIndex].length - 1) {
          item.columnPosition.push('last');
        }
        // set row size
        item.rowSize = layout[rowIndex].length;
        // set item index
        item.index = flattenLayout.findIndex(l => l === item.id) + 1;
        // set focus
        if (!data.some(y => !!y.focused)) {
          item.focused = item.index === 1;
        }
        // return mapped data...
        return item;
      });

    setMatrix(newMatrix);
  }, [data, layout]);

  return (
    <Container areas={areas}>
      {!!matrix.length &&
        matrix.map(item => (
          <Cell
            key={`cell-${item.id}`}
            item={item}
            hasFields={hasFields}
            handleChangeFocus={handleChangeFocus}
            handleKeyBoardEvents={handleKeyBoardEvents}
            handleLayoutAdd={handleLayoutAdd}
            handleMove={handleMove}
            handleRemove={handleRemove}
          />
        ))}
    </Container>
  );
};

export default MatrixOrganizer;
