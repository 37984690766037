import { IApiResponse } from '~/models/api.model';
import { IPage } from '~/models/page.model';
import api from '~/services/api';
import { ISSOState } from '~/store/ducks/sso/types';

class PagesService {
  public async get({
    id,
    sso
  }: {
    id: string;
    sso: ISSOState;
  }): Promise<IPage> {
    const draft: IPage = {
      id: 0,
      owner: 0,
      html: '',
      json: '',
      token: '',
      error: {
        message: ''
      }
    };

    try {
      if (!sso.valid) {
        draft.error = {
          message: 'Failed to authenticate'
        };
        return draft;
      }

      const response = await api.get<IPage>(`/pages/${id}`, {
        headers: {
          token: sso.token,
          refresh_token: sso.refreshToken
        }
      });

      if (response.status !== 200) {
        if (process.env.NODE_ENV === 'development') {
          return draft;
        }

        draft.error = {
          message: `
          [${response.status}] ${response.statusText}
           ${response.data?.error ? `: ${response.data.error.message}` : ''}`
        };
        return draft;
      }

      return response.data;
    } catch (err) {
      console.tron.error(err);
      draft.error = {
        message: `
        Não foi possível recuperar os dados da página!<br />
        <small>Feche essa janela e tente novamente...</small>`
      };
      return draft;
    }
  }

  public async save(id: string, page: IPage, sso: ISSOState): Promise<boolean> {
    const response = await api.post<IApiResponse>(`/pages/${id}`, page, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    if (response.status !== 200) {
      console.error(
        `[${response.status}] - ${
          response.data?.error?.message ?? response.data.message
        }`
      );
      return false;
    }
    console.tron.log('saved page', page);
    return true;
  }

  public async update(
    id: string,
    json: string,
    sso: ISSOState
  ): Promise<boolean> {
    const response = await api.put<IApiResponse>(
      `/pages/${id}`,
      {
        json
      },
      {
        headers: {
          token: sso.token,
          refresh_token: sso.refreshToken
        }
      }
    );

    if (response.status !== 200) {
      console.tron.error(
        `[${response.status}] ${response.data?.error?.message ?? ''}`
      );
      return false;
    }

    return true;
  }
}

export default new PagesService();
