import { IApiResponse } from '~/models/api.model';
import api from '~/services/api';
import { ISSOState } from '~/store/ducks/sso/types';

class EditorService {
  public async close(id: string, sso: ISSOState): Promise<boolean> {
    const response = await api.post<IApiResponse>(`/pages/${id}/editor`, null, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    if (response.status !== 204) {
      console.error(
        `[${response.status}] - ${
          response.data?.error?.message ?? response.data.message
        }`
      );
      return false;
    }

    console.tron.log('closed page editor', id);

    return true;
  }
}

export default new EditorService();
