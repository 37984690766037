import React from 'react';

import { FormStructure } from '@mailupinc/bee-plugin';

export type FormType = 'static' | 'dynamic';

export interface IUserData {
  id: number;
  token: string;
}

export interface IFormSelectorContext {
  dialog: IDialogContext;
}

export interface IDialogContext {
  open(
    structure?: FormStructure,
    user?: IUserData,
    returnExternalContent?: boolean
  ): Promise<unknown>;
}

const FormSelectorContext = React.createContext<IFormSelectorContext>({
  dialog: {
    open: () => new Promise(resolve => resolve(null))
  }
});

export default FormSelectorContext;
