import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import reactotronSaga from 'reactotron-redux-saga';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Console {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tron: any;
  }
}

if (process.env.NODE_ENV === 'development') {
  const tron = Reactotron.configure()
    .use(reactotronRedux())
    .use(reactotronSaga({}))
    .connect();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  tron.clear!();

  console.tron = tron;
} else {
  console.tron = console;
}
