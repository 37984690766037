import styled from 'styled-components';

import ThemeColors from '~/styles/theme-colors';

export const EmptyTemplate = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  max-height: 30vh;
  p {
    margin-top: 16px;
    font-size: 15px;
  }

  .MuiFab-root {
    background-color: ${ThemeColors.primaryColor[100]};
    &:hover {
      background-color: ${ThemeColors.primaryColor[200]};
    }
    .MuiSvgIcon-root {
      color: #fff;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  grid-area: header;
  .MuiSvgIcon-root {
    color: #fff;
    font-size: 25px;
    margin-right: 16px;
    cursor: pointer;
  }
  a {
    color: #fff;
    font-size: 16px;
    padding-right: 40px;
  }
`;

export const Title = styled.h2`
  color: #fff;
  font-weight: 500;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 10vh 90vh;
  grid-template-areas:
    'header header'
    'sidenav content';

  height: 100vh;
  width: 100vw;
`;

export const Sidenav = styled.aside`
  height: calc(100vh - 80px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  grid-area: sidenav;

  .MuiExpansionPanel-root {
    background: transparent;
    margin: 0 !important;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    min-height: 58px;
    &:before {
      content: none;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiExpansionPanelSummary-root {
    flex-direction: row-reverse;
    min-height: 58px;
    &.Mui-expanded {
      transition: 0.3s ease background;
      background: rgba(255, 255, 255, 0.1);
      width: 90%;
      border-radius: 0 30px 30px 0;
      min-height: 58px;
    }
  }

  .MuiCollapse-container.MuiCollapse-entered {
    ul {
      margin-left: 0;
    }
  }

  .MuiExpansionPanelDetails-root {
    padding-left: 70px;
    ul {
      margin-left: -300px;
      transition: margin ease-in-out 0.2s;
      li {
        line-height: 32px;
        font-weight: 400;
        font-size: 15px;
        cursor: pointer;
        transition: 0.3s ease color;
        &.selected {
          color: ${ThemeColors.primaryColor[100]};
        }
      }
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
    color: #fff;
  }

  .label-show-all {
    margin-left: 18px;
    font-size: 15px;
    font-weight: bold;
  }
`;

export const Content = styled.main`
  height: calc(100vh - 80px);
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  overflow: auto;
  padding: 36px;
  flex: 1;
`;
