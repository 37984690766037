import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { Container } from './styles';

import BeePlugin from '~/components/BeePlugin';
import { FormSelectorProvider } from '~/components/FormSelector';
import MessageEditor from '~/components/MessageEditor';
import TemplateSelector from '~/components/TemplateSelector';
import { IPage } from '~/models/page.model';
import PagesService from '~/services/pages';
import { IApplicationState as AppState } from '~/store';
import { SSOActions } from '~/store/ducks/sso/actions';
import { appendCustomAddonsScript } from '~/utils';

type TParams = { id: string };

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Editor = ({ match }: RouteComponentProps<TParams>) => {
  const { id } = match.params;
  const query = useQuery();
  const key = query.get('key') ?? undefined;
  const showCheckout = Boolean(Number(query.get('llpay') ?? '0'));

  const sso = useSelector((state: AppState) => state.sso);
  const [cookies] = useCookies(['leadlovers-sso']);

  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<IPage>();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [template, setTemplate] = useState<unknown>();
  const [templateId, setTemplateId] = useState<number>(0);
  const [pageLoaded, setPageLoaded] = useState<unknown>(null);

  async function onChange(json: string) {
    await PagesService.update(id, json, sso);
  }

  function onError(errorMessage: string) {
    console.tron.error(errorMessage);
    setError(`
      Ops...<br>
      Não foi possível carregar o editor!<br>
      Feche a aba e tente novamente...`);
  }

  function onLoad() {
    setLoading(false);
  }

  async function sendErrorLog(
    errorName: string,
    data: Record<string, unknown>
  ) {
    Sentry.withScope(scope => {
      Object.keys(data).forEach(info => {
        scope.setExtra(info, data[info]);
      });
      scope.setUser({
        email: sso.email
      });
      scope.setTransactionName(errorName);
      Sentry.captureException({ type: 'SentryLog' });
    });
  }

  async function onSave(html: string, json: string, close: boolean) {
    setSaving(true);

    const htmlWithCustomAddonsScript = appendCustomAddonsScript(html);

    const pageToSave: IPage = {
      ...(page as IPage),
      ...{
        html: htmlWithCustomAddonsScript,
        json,
        templateId
      },
      closeEditor: close
    };

    const htmlWhite = `<html><head>  <title></title>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width">  <style>   .bee-row,   .bee-row-content {    position: relative   }    .bee-row-1,   .bee-row-1 .bee-row-content {    background-repeat: no-repeat   }    body {    background-color: #fff;    color: #000;    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif   }    * {    box-sizing: border-box   }    body {    margin: 0   }    .bee-row-content {    max-width: 500px;    margin: 0 auto;    display: flex   }    .bee-row-content .bee-col-w12 {    flex-basis: 100%   }    @media (max-width:520px) {    .bee-row-content:not(.no_stack) {     display: block    }   }    .bee-row-1 .bee-row-content {    color: #000   }    .bee-row-1 .bee-col-1 {    padding-bottom: 5px;    padding-top: 5px   }  </style> </head>  <body>  <div class="bee-page-container">   <div class="bee-row bee-row-1">    <div class="bee-row-content">     <div class="bee-col bee-col-1 bee-col-w12"></div>    </div>   </div>  </div>   <script src="https://beepluginaddons.contato.io/scripts/base-script.js" async=""></script></body></html>`;

    const htmlWhite2 = `<html><head>  <title></title>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width">  <style>   body {    color: #000;    background-color: #fff;    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;   }    * {    box-sizing: border-box;   }    body {    margin: 0;   }    .bee-row {    position: relative;   }    .bee-row-content {    max-width: 500px;    margin: 0 auto;   }    .bee-row-content {    display: flex;    position: relative;   }    .bee-row-content .bee-col-w12 {    flex-basis: 100%;   }    .bee-row-1 {    background-repeat: no-repeat;   }    .bee-row-1 .bee-row-content {    color: #000;    background-repeat: no-repeat;   }    .bee-row-1 .bee-col-1 {    padding-top: 5px;    padding-bottom: 5px;   }    @media (max-width:520px) {     .bee-row-content:not(.no_stack) {     display: block;    }   }  </style> </head>  <body>  <div class="bee-page-container">   <div class="bee-row bee-row-1">    <div class="bee-row-content">     <div class="bee-col bee-col-1 bee-col-w12"></div>    </div>   </div>  </div>   <script src="https://beepluginaddons.contato.io/scripts/base-script.js" async=""></script></body></html>`;

    const formattedPageHtml = pageToSave.html.replace(/\s/g, '');
    const formattedHtmlWhite = htmlWhite.replace(/\s/g, '');
    const formattedHtmlWhite2 = htmlWhite2.replace(/\s/g, '');

    if (
      formattedPageHtml === formattedHtmlWhite ||
      formattedPageHtml === formattedHtmlWhite2
    ) {
      setError('A página está em branco, recarregue e tente salvar novamente');
      setSaving(false);
      setSuccess(false);
      sendErrorLog('Error on save page. Page is empty.', {
        pageToSave,
        html,
        json,
        pageLoaded,
        pageId: id || 'new-page'
      });
      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      const saveResponse = await PagesService.save(id, pageToSave, sso);
      if (saveResponse === false) {
        setError(
          'Ocorreu um erro ao salvar a página!<br><small>Tente novamente</small>'
        );
        setSaving(false);
        setSuccess(false);
      } else {
        setSuccess(true);
        setSaving(false);
        setError('');
        setPage(pageToSave);
        if (close) {
          setTimeout(() => {
            window.close();
          }, 3000);
        }
      }
    }
  }

  useEffect(() => {
    dispatch(SSOActions.loadRequest({ cookies, key }));
  }, [cookies, dispatch, key]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    async function load() {
      if (sso.errorMessage) {
        console.error(sso.errorMessage);
        console.error('> sso:', sso);
        setLoading(false);
        setError(`
          Ops...<br>
          Não foi possível te identificar!<br>
          Fecha a aba e tente novamente...`);
      } else if (sso.valid) {
        const data = await PagesService.get({ id, sso });
        setPageLoaded(data);
        if (data.error?.message) {
          console.error(data.error.message);
          console.error('> data:', { id, sso, data });
          setError(`
            Ops...<br>
            Não foi possível carregar a página!<br>
            Feche esta aba e tente novamente...`);
        } else {
          setPage(data);
          if (data.json) {
            setTemplate(JSON.parse(data.json));
          }
        }
        setLoading(false);
      }
    }
    if (id && sso) {
      setError('');
      setLoading(true);
      setTimeout(() => {
        load();
      }, 100);
    }
  }, [id, sso]);

  return (
    <FormSelectorProvider>
      <Container>
        {page && !loading && (
          <>
            {template && (
              <BeePlugin
                onCleanTemplate={() => setTemplate(null)}
                page={page}
                template={template}
                showCheckout={showCheckout}
                onChange={onChange}
                onError={onError}
                onLoad={onLoad}
                onSave={onSave}
              />
            )}
            {!template && (
              <TemplateSelector
                pageId={id}
                onSelect={(t: unknown, tId) => {
                  setTemplate(t);
                  if (tId) setTemplateId(tId);
                }}
              />
            )}
          </>
        )}
      </Container>
      {(loading || error || saving || success) && (
        <MessageEditor
          loading={loading}
          error={error}
          saving={saving}
          success={success}
        />
      )}
    </FormSelectorProvider>
  );
};

export default Editor;
