import styled from 'styled-components';

export interface IContainerProps {
  area: string;
  focused: boolean;
}

export const Container = styled.div<IContainerProps>`
  grid-area: ${props => props.area};
  border: 1px dashed
    ${props => (props.focused ? '#d4cfa6 !important' : '#b1b1b1')};
  padding: 10px;
  background-color: ${props =>
    props.focused ? '#f5f3e2 !important' : '#f5f5f5'};
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: default;

  > * {
    width: 100%;
  }

  &:hover {
    background-color: #dff3fb;
    border-color: #7abad4;
  }
`;
