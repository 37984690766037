import styled from 'styled-components';

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 5px;
  }
`;

export const Label = styled.input`
  padding: 3px;
  color: #333;
  font-weight: bold;
  border: none;
  background-color: transparent;
`;

export const Select = styled.select`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  height: 38px;
`;

export const TextField = styled.input`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
`;

export const TextArea = styled.textarea`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  resize: none;
`;
