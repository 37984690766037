import React from 'react';

import { Button, AddIcon, MoveIcon, RemoveIcon, ActionType } from './styles';

interface IActionButtonProps {
  type: ActionType;
  onClick: () => void;
}

const ActionButton = ({ onClick, type }: IActionButtonProps) => (
  <Button
    size={type === 'remove' ? 'medium' : 'small'}
    color={type === 'remove' ? 'secondary' : 'primary'}
    act={type}
    tabIndex={-1}
    onClick={onClick}
  >
    {type === 'add' && <AddIcon />}
    {type === 'remove' && <RemoveIcon />}
    {type === 'up' && <MoveIcon direction={type} />}
    {type === 'right' && <MoveIcon direction={type} />}
    {type === 'down' && <MoveIcon direction={type} />}
    {type === 'left' && <MoveIcon direction={type} />}
  </Button>
);

export default ActionButton;
