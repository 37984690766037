import styled from 'styled-components';

import ThemeColors from '~/styles/theme-colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

export const FloatingContainer = styled.div`
  background: #33373a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorWrapper = styled.div`
  background: #212121;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;

  svg {
    margin-bottom: 20px;
  }

  h3 {
    color: ${ThemeColors.primaryColor[100]};
    line-height: 25px;
    text-align: center;

    small {
      color: #d5d6d7;
    }
  }
`;

export const SuccessWrapper = styled.div`
  background: #212121;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;

  svg {
    margin-bottom: 20px;
  }

  h3 {
    color: #43bb39;
    line-height: 25px;
    text-align: center;
  }
`;
