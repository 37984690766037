/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  TextField,
  Typography,
  IconButton,
  Box,
  CircularProgress
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import { IDialogSettings } from './hooks/useSaveRowDialog';
import { DialogContent } from './styles';

import ThemeColors from '~/styles/theme-colors';

interface IProps {
  configDialog: IDialogSettings;
  isLoading: boolean;
}

const SaveComponentDialog: React.FC<IProps> = ({ configDialog, isLoading }) => {
  const [nameComponent, setNameComponent] = useState('');

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: ThemeColors.primaryColor[50],
        main: ThemeColors.primaryColor[100],
        dark: ThemeColors.primaryColor[200],
        contrastText: '#fff'
      },
      secondary: {
        light: '#787d80',
        main: '#515658',
        dark: '#383e40',
        contrastText: '#fff'
      }
    }
  });

  const onHandleSave = useCallback(() => {
    configDialog.save({
      success: true,
      name: nameComponent
    });
  }, [configDialog, nameComponent]);

  const handleOnChange = React.useCallback(event => {
    event.preventDefault();
    setNameComponent(event?.target?.value);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Dialog maxWidth="sm" open={configDialog.isOpen}>
        <DialogContent>
          <Box marginTop={-1} display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" className="title">
                {configDialog.type} novo bloco
              </Typography>
            </Box>
            <Box marginRight={-1} display="flex" alignItems="center">
              <IconButton
                aria-label="close"
                onClick={() => configDialog.close()}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <TextField
              type="text"
              label="Nome do bloco"
              variant="outlined"
              required
              className="input"
              size="medium"
              onChange={handleOnChange}
            />
            <div className="button-container">
              <Button
                variant="outlined"
                onClick={onHandleSave}
                size="large"
                className="button"
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Typography variant="body2">{configDialog.type}</Typography>
                )}
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default SaveComponentDialog;
