/* eslint-disable no-useless-escape */
export const convertToKey = (value: string): string => {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    .replace(/º/gi, 'o')
    .replace(/ª/gi, 'a')
    .replace(/[^\w\s]/gi, '')
    .trim()
    .replace(/ /g, '_')
    .toLowerCase();
};

export const convertToOldKey = (value: string) => {
  return value.replace(/[\W_]+/g, '');
};

export const appendCustomAddonsScript = (html: string) => {
  const baseScript = document.createElement('script');
  baseScript.src = `${process.env.REACT_APP_ADDONS_URL}/scripts/base-script.js`;
  baseScript.setAttribute('async', '');

  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, 'text/html');
  parsedHtml.body.appendChild(baseScript);

  return `<html>${parsedHtml.documentElement.innerHTML}</html>`;
};
