import { IFieldModel } from '~/models/field.model';
import api from '~/services/api';

export interface IRowData {
  name: string;
  owner: number;
  formType: string;
  fields: IFieldModel[];
  layout: string[][];
}

interface IRowResponse {
  key?: string;
  error?: {
    message: string;
  };
}

class RowsService {
  public async save(newRow: IRowData): Promise<IRowResponse> {
    const response = await api.post<IRowResponse>(`/rows`, newRow);

    if (response.status !== 200) {
      console.error(
        `[${response.status}] - ${
          response.data?.error?.message ?? 'Undefined Error'
        }`
      );
      return response.data;
    }

    console.tron.log('saved row:', response.data.key);

    return response.data;
  }
}

export default new RowsService();
