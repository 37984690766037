import { DialogContent as MuiDialogContent } from '@material-ui/core';
import styled from 'styled-components';

interface IColumnProps {
  mright?: number;
  mtop?: number;
}

export const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
`;

export const EndRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;

  > button {
    margin-left: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div<IColumnProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-right: ${props => props.mright || 0}px;
  margin-top: ${props => props.mtop || 0}px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`;

export const FormSolidColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  border: 1px solid #ccc;
  margin-top: 5px;
  position: relative;
  background-color: #f5f5f5;
  padding: 10px 15px;
`;

export const FieldList = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  padding: 20px 10px;
`;

export const Loading = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
