import React, { useState, useEffect } from 'react';

import ActionButton from './ActionButton';
import { Action, Position, FlexDirection } from './styles';

import { ItemPosition, Direction } from '~/models/matrix.model';

interface ICellActionsProps {
  rowSize: number;
  rowPosition: ItemPosition[];
  columnPosition: ItemPosition[];
  canBeRemoved: boolean;
  canShareRow: boolean;
  hasFields: boolean;
  handleAdd: (direction: Direction) => void;
  handleMove: (direction: Direction) => void;
  handleRemove: () => void;
}

interface ICellActions {
  position: Position;
  direction: Direction;
  flexDirection: FlexDirection;
  add: boolean;
  move: boolean;
  remove?: boolean;
}

const CellActions = ({
  rowSize,
  rowPosition,
  columnPosition,
  canBeRemoved,
  canShareRow,
  hasFields,
  handleAdd,
  handleMove,
  handleRemove
}: ICellActionsProps) => {
  const [actions, setActions] = useState<ICellActions[]>([]);

  useEffect(() => {
    const mappedActions: ICellActions[] = [
      {
        position: 'top',
        direction: 'up',
        flexDirection: 'row',
        add: hasFields,
        move: !rowPosition.includes('first') || rowSize > 1,
        remove: canBeRemoved
      },
      {
        position: 'right',
        direction: 'right',
        flexDirection: 'column',
        add: hasFields && rowSize < 3 && canShareRow,
        move: rowSize > 1 && !columnPosition.includes('last')
      },
      {
        position: 'bottom',
        direction: 'down',
        flexDirection: 'row',
        add: hasFields,
        move: !rowPosition.includes('last') || rowSize > 1
      },
      {
        position: 'left',
        direction: 'left',
        flexDirection: 'column',
        add: hasFields && rowSize < 3 && canShareRow,
        move: rowSize > 1 && !columnPosition.includes('first')
      }
    ];
    setActions(mappedActions);
    // eslint-disable-next-line
  }, [rowSize, rowPosition, columnPosition, hasFields]);

  return (
    <>
      {actions.map(act => (
        <Action
          key={`box-${act.position}`}
          position={act.position}
          flexDirection={act.flexDirection}
        >
          {act.add && (
            <ActionButton type="add" onClick={() => handleAdd(act.direction)} />
          )}
          {act.move && (
            <ActionButton
              type={act.direction}
              onClick={() => handleMove(act.direction)}
            />
          )}
          {act.remove && (
            <ActionButton type="remove" onClick={() => handleRemove()} />
          )}
        </Action>
      ))}
    </>
  );
};

export default CellActions;
