import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Container, Badge } from './styles';

import { ITemplate } from '~/models/template.model';
import TemplatesService from '~/services/templates';
import { IApplicationState as AppState } from '~/store';

interface IProps {
  template: ITemplate;
  onClick: (json: unknown, templateId: number) => void;
}

const PageTemplate = ({ template, onClick }: IProps) => {
  const sso = useSelector((state: AppState) => state.sso);

  async function handleTemplateClick() {
    const dbTemplate = await TemplatesService.getTemplateById({
      sso,
      id: template.id
    });
    onClick(JSON.parse(dbTemplate.json ?? '{}'), template.id);
  }

  return (
    <Container
      role="button"
      onClick={handleTemplateClick}
      onKeyDown={handleTemplateClick}
    >
      <img src={template.thumbnail} alt={template.name} />
      <p>
        {template.name}
        {moment(template.createdAt) >= moment().subtract(15, 'd') && (
          <Badge>NOVO</Badge>
        )}
      </p>
    </Container>
  );
};

export default PageTemplate;
