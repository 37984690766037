import styled from 'styled-components';

import ThemeColors from '~/styles/theme-colors';

export const Container = styled.div`
  height: 55px;
  width: 100vw;

  background-color: #515658;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  button {
    padding: 8px 16px;
    text-transform: none;
  }

  button.MuiButton-containedSizeSmall {
    height: 32px !important;
  }

  hr.MuiDivider-light {
    background-color: rgba(255, 255, 255, 0.08);
  }

  .menu-direction-span {
    color: #fff;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 68px;
    .MuiIcon {
      color: #fff;
    }
  }

  .MuiButtonGroup-root .MuiButton-contained {
    color: #fff;
    border-color: transparent;
    background-color: #363636;

    &.selected {
      background-color: ${ThemeColors.primaryColor[100]};
    }
  }
`;
