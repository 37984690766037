import { ITemplate, ITemplateCategory } from '~/models/template.model';
import api from '~/services/api';
import { ISSOState } from '~/store/ducks/sso/types';

class TemplatesService {
  public async getTemplates({ sso }: { sso: ISSOState }): Promise<ITemplate[]> {
    const response = await api.get<ITemplate[]>(`/templates`, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    return response.data;
  }

  public async getTemplateById({
    sso,
    id
  }: {
    sso: ISSOState;
    id: number;
  }): Promise<ITemplate> {
    const response = await api.get<ITemplate>(`/templates/${id}`, {
      headers: {
        token: sso.token,
        refresh_token: sso.refreshToken
      }
    });

    return response.data;
  }

  public async getCategories({
    sso
  }: {
    sso: ISSOState;
  }): Promise<ITemplateCategory[]> {
    const response = await api.get<ITemplateCategory[]>(
      `/templates_categories`,
      {
        headers: {
          token: sso.token,
          refresh_token: sso.refreshToken
        }
      }
    );

    return response.data;
  }
}

export default new TemplatesService();
