import { IconButton } from '@material-ui/core';
import { PlayCircleFilledRounded, AddCircle } from '@material-ui/icons';
import styled from 'styled-components';

export type ActionType = 'add' | 'remove' | 'up' | 'right' | 'down' | 'left';
export type Direction = 'up' | 'right' | 'down' | 'left';

interface IButtonProps {
  act: ActionType;
}

interface IMoveIconProps {
  direction: Direction;
}

export const Button = styled(IconButton)<IButtonProps>`
  padding: 1px !important;
  width: auto !important;
  position: ${props =>
    props.act === 'remove' ? 'absolute' : 'relative'} !important;
  right: ${props => (props.act === 'remove' ? '-15px' : 'auto')};
  z-index: 10;
`;

export const AddIcon = styled(AddCircle).attrs(props => ({
  fontSize: props.fontSize ?? 'small'
}))``;

export const MoveIcon = styled(PlayCircleFilledRounded).attrs(props => ({
  fontSize: props.fontSize ?? 'small'
}))<IMoveIconProps>`
  transform: rotate(
    ${props => {
      switch (props.direction) {
        case 'up':
          return '270deg';
        case 'down':
          return '90deg';
        case 'left':
          return '180deg';
        default:
          return '0deg';
      }
    }}
  );
`;

export const RemoveIcon = styled(AddCircle)`
  transform: rotate(45deg);
`;
