import styled from 'styled-components';

interface IGridAreas {
  areas: string[];
}

interface IGridCell {
  area: string;
  focused: boolean;
  autoFocus?: boolean;
}

export const Container = styled.div<IGridAreas>`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: ${props => `'${props.areas.join("'\n'")}'`};
`;
