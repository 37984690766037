import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { SSOActions } from './actions';
import { IApiError, ICookies, ISSOSession, ISession, SSOTypes } from './types';

import api from '~/services/api';

function findSession(key: string) {
  return api.get<ISSOSession | IApiError>(`/sessions/${key}`);
}

function createSession(data: ISession) {
  return api.post<ISession | IApiError>(`/sessions`, data);
}

function parseSessionCredentials(
  cookies?: ICookies,
  ssoSession?: ISSOSession
): ISession | undefined {
  const baseToken =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEBUG_TOKEN
      : '';
  const baseRefreshToken =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEBUG_REFRESH_TOKEN
      : '';

  const rawStoredSession = sessionStorage.getItem('leadlovers_sso');
  if (rawStoredSession) {
    const storedSession = JSON.parse(rawStoredSession);
    return {
      token: storedSession.token ?? baseToken,
      refreshToken: storedSession.refreshToken ?? baseRefreshToken
    } as ISession;
  }

  if (cookies?.leadlovers_sso?.token && cookies?.leadlovers_sso?.refreshToken) {
    return {
      token: cookies?.leadlovers_sso?.token ?? baseToken,
      refreshToken: cookies?.leadlovers_sso?.refreshToken ?? baseRefreshToken
    } as ISession;
  }

  if (ssoSession) {
    return {
      token: ssoSession.token ?? baseToken,
      refreshToken: ssoSession.refreshToken ?? baseRefreshToken
    };
  }

  return undefined;
}

export function* loadRequest(
  action: ReturnType<typeof SSOActions.loadRequest>
) {
  try {
    if (action.payload) {
      const { cookies, key } = action.payload;

      let credentials = parseSessionCredentials(cookies);
      if (!credentials) {
        const storedSSOKey = sessionStorage.getItem('leadlovers_sso_key');
        const ssoKey = storedSSOKey ?? key;
        if (ssoKey) {
          const ssoCredentialsResponse: AxiosResponse<
            ISSOSession | IApiError
          > = yield call(findSession, ssoKey);
          if (ssoCredentialsResponse.status !== 200) {
            const { error } = ssoCredentialsResponse.data as IApiError;
            yield put(SSOActions.loadFailure({ errorMessage: error.message }));
          } else {
            const ssoCredentials = ssoCredentialsResponse.data as ISSOSession;

            sessionStorage.setItem('leadlovers_sso_key', ssoCredentials.key);
            sessionStorage.setItem(
              'leadlovers_sso',
              JSON.stringify(ssoCredentials)
            );

            credentials = parseSessionCredentials(undefined, ssoCredentials);
          }
        }
      }

      if (!credentials) {
        yield put(
          SSOActions.loadFailure({
            errorMessage:
              'Failed to get session credentials from cookies and sso key.'
          })
        );
      } else {
        const response: AxiosResponse<ISession | IApiError> = yield call(
          createSession,
          credentials
        );
        if (response.status !== 200) {
          const { error } = response.data as IApiError;
          yield put(SSOActions.loadFailure({ errorMessage: error.message }));
        } else {
          const session = response.data as ISession;
          yield put(
            SSOActions.loadSuccess({
              type: 'bearer',
              token: session.token,
              refreshToken: session.refreshToken,
              email: session?.email,
              name: session?.name
            })
          );
        }
      }
    }
  } catch (err) {
    console.tron.error(err);
    yield put(SSOActions.loadFailure({ errorMessage: (err as Error).message }));
  }
}

export default all([takeLatest(SSOTypes.LOAD_REQUEST, loadRequest)]);
