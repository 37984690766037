import axios from 'axios';

const leadlovers = axios.create({
  baseURL:
    process.env.REACT_APP_LEADLOVERS_API_URL ||
    'https://llapi.leadlovers.com/webapi/',
  headers: {
    get: {
      Authorization: `Bearer ${
        process.env.REACT_APP_LEADLOVERS_API_TOKEN ||
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfbmFtZSI6IldlYkFwaSIsInN1YiI6IldlYkFwaSIsInJvbGUiOlsicmVhZCIsIndyaXRlIl0sImlzcyI6Imh0dHA6Ly93ZWJhcGlsbC5henVyZXdlYnNpdGVzLm5ldCIsImF1ZCI6IjFhOTE4YzA3NmE1YjQwN2Q5MmJkMjQ0YTUyYjZmYjc0IiwiZXhwIjoxNjA1NDQxMzM4LCJuYmYiOjE0NzU4NDEzMzh9.YIIpOycEAVr_xrJPLlEgZ4628pLt8hvWTCtjqPTaWMs'
      }`
    }
  },
  validateStatus: status => status !== 500
});

export default leadlovers;
