import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createStore from './create';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import { ISSOState } from './ducks/sso/types';

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

export interface IApplicationState {
  sso: ISSOState;
}

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const store: Store<IApplicationState> = createStore(rootReducer, [
  sagaMiddleware
]);

sagaMiddleware.run(rootSaga);

export { store };
