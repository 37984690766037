/* eslint-disable @typescript-eslint/no-empty-function */

import { useState, useCallback } from 'react';

export interface IDialogSettings {
  isOpen: boolean;
  type: string;
  args?: any;
  close: () => void;
  save: (results: IResults) => void;
}

interface IResults {
  success: boolean;
  name: string;
}

const useSaveRowDialog = () => {
  const [saveRowDialog, setSaveRowDialog] = useState<IDialogSettings>({
    isOpen: false,
    type: '',
    close: () => {},
    save: () => {}
  });

  const setOpen = (modalSettings: IDialogSettings) => {
    setSaveRowDialog(modalSettings);
  };

  const setClose = () => {
    setSaveRowDialog({
      isOpen: false,
      type: '',
      close: () => {},
      save: () => {}
    });
  };

  const openModal = useCallback((args, type) => {
    return new Promise((resolve, reject) => {
      const close = () => {
        setClose();
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('');
      };
      const save = (results: IResults) => {
        resolve({ ...results });
      };

      const dialogSettings: IDialogSettings = {
        isOpen: true,
        type,
        args,
        close,
        save
      };

      setOpen(dialogSettings);
    }).catch(error => console.error(error));
  }, []);

  return { openModal, setClose, saveRowDialog };
};

export default useSaveRowDialog;
