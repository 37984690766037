import { useContext } from 'react';

import FormSelectorContext from './context';

const useFormSelector = () => {
  const { dialog } = useContext(FormSelectorContext);
  return dialog;
};

export default useFormSelector;
