/* eslint-disable @typescript-eslint/no-empty-function */

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import ComponentsService from '~/services/components';
import { IApplicationState as AppState } from '~/store';

export interface IRowComponent {
  Id?: number;
  Json: string;
  IdComponent: string;
  Name: string;
  UserId?: string;
}

const useComponentsRow = () => {
  const [loadingRowComponents, setLoadingRowComponents] = useState(false);

  const sso = useSelector((state: AppState) => state.sso);

  const saveRowComponent = useCallback(
    async jsonFile => {
      setLoadingRowComponents(true);
      const json = JSON.parse(jsonFile);

      const idComponent = uuidv4();

      const newJson = {
        ...json,
        metadata: {
          ...json.metadata,
          id: idComponent
        }
      };

      const { name } = json.metadata;

      const component = {
        json: JSON.stringify(newJson),
        idComponent,
        name
      };

      const response = await ComponentsService.save({
        component,
        sso
      });

      if (response.error) {
        setLoadingRowComponents(false);

        return false;
      }

      setLoadingRowComponents(false);
      return true;
    },
    [sso]
  );

  const deleteRowComponent = useCallback(
    async idComponent => {
      const response = await ComponentsService.delete(idComponent, sso);

      return response;
    },
    [sso]
  );

  return {
    saveRowComponent,
    deleteRowComponent,
    loadingRowComponents
  };
};

export default useComponentsRow;
