/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export type Position = 'top' | 'right' | 'bottom' | 'left';
export type FlexDirection = 'row' | 'column';

interface ICellActionProps {
  position: Position;
  flexDirection: FlexDirection;
}

export const Action = styled.div<ICellActionProps>`
  display: flex;
  position: absolute !important;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.flexDirection};
  width: ${props =>
    props.flexDirection === 'row' ? '100%' : '30px'} !important;
  height: ${props =>
    props.flexDirection === 'row' ? '30px' : '100%'} !important;
  top: ${props =>
    props.position === 'top'
      ? '-15px'
      : props.flexDirection === 'column'
      ? '0px'
      : 'auto'};
  right: ${props => (props.position === 'right' ? '-15px' : 'auto')};
  bottom: ${props => (props.position === 'bottom' ? '-15px' : 'auto')};
  left: ${props => (props.position === 'left' ? '-15px' : 'auto')};
`;
