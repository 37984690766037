import styled from 'styled-components';

import ThemeColors from '~/styles/theme-colors';

export const Container = styled.div`
  cursor: pointer;
  :hover p {
    color: ${ThemeColors.primaryColor[100]};
  }
  p {
    transform: color 0.3s ease;
    color: #fff;
    line-height: 48px;
    font-size: 16px;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export const Badge = styled.span`
  background-color: ${ThemeColors.primaryColor[100]};
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  padding: 3px 10px;
  margin-left: 10px;
`;
