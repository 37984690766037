/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { FormStructure } from '@mailupinc/bee-plugin';

import FormSelectorContext, { IUserData } from './context';
import FormSelectorDialog, { IFormSelectorProps } from './Dialog';

interface IProps {
  children: React.ReactNode;
}

class FormSelectorProvider extends React.PureComponent<
  {},
  { formDialog: IFormSelectorProps }
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      formDialog: {
        open: false,
        resolve: () => {},
        reject: () => {}
      }
    };
  }

  handleReject = () => {
    const { formDialog } = this.state;
    this.setState({ formDialog: { ...formDialog, open: false } });
    return formDialog.reject();
  };

  handleResolve = (value: unknown) => {
    const { formDialog } = this.state;
    this.setState({ formDialog: { ...formDialog, open: false } });
    return formDialog.resolve(value);
  };

  open = (
    structure?: FormStructure,
    user?: IUserData,
    returnExternalContent?: boolean
  ) => {
    return new Promise((resolve, reject) => {
      this.setState({
        formDialog: {
          open: true,
          returnExternalContent,
          structure,
          user,
          resolve,
          reject
        }
      });
    });
  };

  render() {
    const { children } = this.props;
    const { formDialog } = this.state;
    const dialog = { open: this.open };

    return (
      <FormSelectorContext.Provider value={{ dialog }}>
        {children}
        {formDialog && (
          <FormSelectorDialog
            {...formDialog}
            open={formDialog.open}
            structure={formDialog.structure}
            user={formDialog.user}
            returnExternalContent={formDialog.returnExternalContent}
            resolve={this.handleResolve}
            reject={this.handleReject}
          />
        )}
      </FormSelectorContext.Provider>
    );
  }
}

export default FormSelectorProvider;
