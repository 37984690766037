/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

import {
  Button as FieldButton,
  FieldBox,
  FieldGroup,
  FieldNumber,
  FieldRow,
  Label,
  Select,
  TextField,
  TextArea
} from './styles';

import useDebounce from '~/hooks/useDebounce';
import { IFieldModel } from '~/models/field.model';

export type FieldType = 'cols' | 'label' | 'placeholder' | 'required' | 'value';

interface IFieldData {
  content: IFieldModel;
  onChange: (key: string, type: FieldType, value: any, isHidden?: boolean) => void;
}

const customTypes = ['checkbox', 'radio', 'select', 'submit', 'textarea'];

const MatrixField: React.FC<IFieldData> = ({
  content,
  onChange
}: IFieldData) => {
  const [columns, setColumns] = useState(content.cols ?? 1);
  const [label, setLabel] = useState(content.label ?? '');
  const [placeholder, setPlaceholder] = useState(content.placeholder ?? '');
  const [required, setRequired] = useState(content.required ?? false);
  const [value, setValue] = useState(content.value ?? '');

  const debouncedColumns = useDebounce(columns, 500);
  const debouncedLabel = useDebounce(label, 500);
  const debouncedPlaceholder = useDebounce(placeholder, 500);
  const debouncedRequired = useDebounce(required, 500);
  const debouncedValue = useDebounce(value, 500);

  const toggleRequired = () => {
    setRequired(current => !current);
  };

  const handleColumnsChange = (newValue: number) => {
    if (newValue >= 1 && newValue <= 5) {
      setColumns(newValue);
    }
  };

  useEffect(() => {
    if (debouncedColumns) {
      onChange(content.key, 'cols', debouncedColumns);
    }
    // eslint-disable-next-line
  }, [debouncedColumns]);

  useEffect(() => {
    if (debouncedLabel) {
      onChange(content.key, 'label', debouncedLabel);
    }
    // eslint-disable-next-line
  }, [debouncedLabel]);

  useEffect(() => {
    if (debouncedPlaceholder) {
      onChange(content.key, 'placeholder', debouncedPlaceholder);
    }
    // eslint-disable-next-line
  }, [debouncedPlaceholder]);

  useEffect(() => {
    if (typeof debouncedRequired !== 'undefined') {
      onChange(content.key, 'required', debouncedRequired);
    }
    // eslint-disable-next-line
  }, [debouncedRequired]);

  useEffect(() => {
    if (debouncedValue) {
      onChange(content.key, 'value', debouncedValue, content.type === 'hidden');
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    setColumns(content.cols ?? 1);
    setLabel(content.label ?? '');
    setPlaceholder(content.placeholder ?? '');
    setRequired(content.required ?? false);
    setValue(content.value ?? '');
  }, [content]);

  content.isTextField = !customTypes.includes(content.type);

  return (
    <FieldBox id={content.key}>
      {content.type !== 'submit' && (
        <FieldRow>
          {content.key !== 'sck' &&
              <Label
              value={content.options?.length ? placeholder : label}
              tabIndex={-1}
              onChange={e =>
                content.options?.length
                  ? setPlaceholder(e.target.value)
                  : setLabel(e.target.value)
              }
            />
          }
          {content.key === 'sck' &&
            <Label
              value="Identificador SCK"
              tabIndex={-1}
            />
          }
          {(content.type === 'checkbox' || content.type === 'radio') && (
            <>
              <label>colunas</label>
              <FieldNumber
                value={columns}
                onChange={e => handleColumnsChange(+e.target.value)}
              />
            </>
          )}
          {content.type !== 'hidden' && (
            <FormGroup>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    size="small"
                    checked={required}
                    color="primary"
                    onChange={toggleRequired}
                  />
                }
                label="obrigatório"
                labelPlacement="start"
              />
            </FormGroup>
          )}
        </FieldRow>
      )}
      {content.isTextField && content.type !== 'hidden' && (
        <TextField
          value={placeholder}
          tabIndex={-1}
          onChange={e => setPlaceholder(e.target.value)}
        />
      )}
      {content.isTextField && content.type === 'hidden' && (
        <TextField
          placeholder='Adicione o valor para o campo identificador SCK'
          value={value}
          tabIndex={-1}
          onChange={e => setValue(e.target.value)}
        />
      )}
      {content.type === 'textarea' && (
        <TextArea
          value={placeholder}
          cols={content.cols}
          tabIndex={-1}
          onChange={e => setPlaceholder(e.target.value)}
        />
      )}
      {content.type === 'submit' && (
        <FieldButton
          value={value}
          tabIndex={-1}
          onChange={e => setValue(e.target.value)}
        />
      )}
      {content.type === 'checkbox' && (
        <FieldGroup columns={columns}>
          {content.options?.map((opt, index) => (
            <label key={`${content.key}_${index}`}>
              <input type="checkbox" name={content.key} value={opt} />
              {opt}
            </label>
          ))}
        </FieldGroup>
      )}
      {content.type === 'radio' && (
        <FieldGroup columns={columns}>
          {content.options?.map((opt, index) => (
            <label key={`${content.key}_${index}`}>
              <input type="radio" name={content.key} value={opt} />
              {opt}
            </label>
          ))}
        </FieldGroup>
      )}
      {content.type === 'select' && (
        <Select tabIndex={-1}>
          {content.options?.map((opt, index) => (
            <option key={`${content.key}_${index}`} value={opt}>
              {opt}
            </option>
          ))}
        </Select>
      )}
    </FieldBox>
  );
};

export default MatrixField;
