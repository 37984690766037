import React, { useEffect, useRef } from 'react';

import CellActions from './CellActions';
import { Container } from './styles';

import { IMatrixContent, Direction } from '~/models/matrix.model';

interface ICellProps {
  item: IMatrixContent;
  hasFields: boolean;
  handleChangeFocus: (item: IMatrixContent) => void;
  handleKeyBoardEvents: (
    id: string,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => boolean;
  handleLayoutAdd: (id: string, direction: Direction) => void;
  handleMove: (id: string, direction: Direction) => void;
  handleRemove: (id: string) => void;
}

const Cell: React.FC<ICellProps> = ({
  item,
  hasFields,
  handleChangeFocus,
  handleKeyBoardEvents,
  handleLayoutAdd,
  handleMove,
  handleRemove
}: ICellProps) => {
  const cellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cellRef && cellRef.current && item.focused) {
      cellRef.current!.focus();
    }
  }, [item, item.focused]);

  return (
    <Container
      area={item.id}
      focused={item.focused ?? false}
      tabIndex={item.index ?? 1}
      onFocus={() => handleChangeFocus(item)}
      onKeyDown={evt => handleKeyBoardEvents(item.id, evt)}
      ref={cellRef}
    >
      {item.content}
      {item.focused && (
        <CellActions
          rowSize={item.rowSize ?? 1}
          rowPosition={item.rowPosition ?? []}
          columnPosition={item.columnPosition ?? []}
          canBeRemoved={item.canBeRemoved}
          canShareRow={item.canShareRow ?? true}
          hasFields={hasFields}
          handleAdd={direction => handleLayoutAdd(item.id, direction)}
          handleMove={direction => handleMove(item.id, direction)}
          handleRemove={() => handleRemove(item.id)}
        />
      )}
    </Container>
  );
};

export default Cell;
