import styled from 'styled-components';

import ThemeColors from '~/styles/theme-colors';

export const Container = styled.div`
  padding: 30px;
  height: 100%;
  background-color: #fff;
`;

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;

  button {
    margin: 10px 0;
    padding: 6px 12px;
    background: ${ThemeColors.primaryColor[50]};
    color: ${ThemeColors.primaryColor[200]};
    border: 1px solid ${ThemeColors.primaryColor[200]};
    border-radius: 4px;
  }
`;
