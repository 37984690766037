import styled from 'styled-components';

interface IFieldGroupProps {
  columns: number;
}

export const Button = styled.input`
  background-color: #3aaee0;
  border: 1px solid #3aaee0;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 20px;
  height: 38px;
  border-radius: 5px;
  text-align: center;
`;

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const FieldGroup = styled.div<IFieldGroupProps>`
  display: grid;
  grid-gap: 0 5px;
  grid-template-columns: repeat(${props => props.columns}, 1fr);

  input {
    margin: 5px;
  }
`;

export const FieldNumber = styled.input.attrs(() => ({
  type: 'number',
  min: 1,
  max: 5
}))`
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px 7px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1px;
  align-items: center;
  justify-content: center;

  > label {
    font-size: 14px;
    color: #666;
  }

  > div.MuiFormGroup-root {
    > label {
      margin: 0 0 0 5px !important;
    }
  }
`;

export const Label = styled.input`
  padding: 3px;
  color: #333;
  font-weight: bold;
  border: none;
  background-color: transparent;
  flex: 1;
  min-width: 30px;
`;

export const Select = styled.select`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  height: 38px;
`;

export const TextField = styled.input`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
`;

export const TextArea = styled.textarea`
  background-color: #fff;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  resize: none;
`;
