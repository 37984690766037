import { AnyAction } from 'redux';

/**
 * Actions
 */
export enum SSOTypes {
  LOAD_FAILURE = '@sso/LOAD_FAILURE',
  LOAD_REQUEST = '@sso/LOAD_REQUEST',
  LOAD_SUCCESS = '@sso/LOAD_SUCCESS'
}

/**
 * Action Payloads
 */
export interface ILoadFailure {
  errorMessage: string;
}

export interface ILoadRequest {
  cookies: ICookies;
  key?: string;
}

export interface ILoadSuccess {
  type: string;
  token: string;
  refreshToken: string;
  name?: string;
  email?: string;
}

/**
 * Action Types
 */
export interface ILoadFailureAction extends AnyAction {
  type: typeof SSOTypes.LOAD_FAILURE;
  payload?: ILoadFailure;
}

export interface ILoadRequestAction extends AnyAction {
  type: typeof SSOTypes.LOAD_REQUEST;
  payload?: ILoadRequest;
}

export interface ILoadSuccessAction extends AnyAction {
  type: typeof SSOTypes.LOAD_SUCCESS;
  payload?: ILoadSuccess;
}

/**
 * Chat Action Types
 */
export type SSOActionTypes =
  | ILoadFailureAction
  | ILoadRequestAction
  | ILoadSuccessAction;

/**
 * Data Types
 */
export interface IApiErrorMessage {
  message: string;
}

export interface IApiError {
  error: IApiErrorMessage;
}

export interface ICookies {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

export interface ISession {
  token: string;
  refreshToken: string;
  name?: string;
  email?: string;
}

export interface ISSOSession {
  type: string;
  token: string;
  refreshToken: string;
  userToken: string;
  key: string;
}

/**
 * State Types
 */
export interface ISSOState {
  readonly type: string;
  readonly token: string;
  readonly name?: string;
  readonly email?: string;
  readonly refreshToken: string;
  readonly errorMessage: string;
  readonly valid: boolean;
}
