/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Fab } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Container,
  Content,
  EmptyTemplate,
  Header,
  Sidenav,
  Title
} from './styles';

import PageTemplate from '~/components/PageTemplate';
import { ITemplate, ITemplateCategory } from '~/models/template.model';
import EditorService from '~/services/editor';
import TemplatesService from '~/services/templates';
import { IApplicationState as AppState } from '~/store';

interface IProps {
  pageId: string;
  onSelect: (json: unknown, templateId?: number) => void;
}

type IDataMap = Map<number, { name: string; items: Map<string, ITemplate[]> }>;

const TemplateSelector = ({ onSelect, pageId }: IProps) => {
  const [dataMap, setDataMap] = useState<IDataMap>();
  const [selectedTemplates, setSelectedTemplates] = useState<ITemplate[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');
  const [allTemplates, setAllTemplates] = useState<ITemplate[]>([]);
  const [expanded, setExpanded] = useState<string | boolean>('panel-all');
  const sso = useSelector((state: AppState) => state.sso);

  const buildMap = (
    categories: ITemplateCategory[],
    templates: ITemplate[]
  ) => {
    const allTemplatesList: ITemplate[] = [];
    const myMap: IDataMap = new Map<
      number,
      { name: string; items: Map<string, ITemplate[]> }
    >();
    categories.forEach(category => {
      myMap.set(category.id, {
        name: category.name,
        items: new Map<string, ITemplate[]>()
      });
    });

    templates.forEach(template => {
      const curMap = myMap.get(template.categoryId);
      if (!curMap) return;
      const { items } = curMap;
      const { name } = template;
      if (items.has(template.name)) {
        template.name = `${name} ${(items.get(name)?.length ?? 0) + 1}`;
        curMap.items.set(name, [...(items.get(name) ?? []), template]);
      } else {
        template.name = `${name} 1`;
        curMap.items.set(name, [template]);
      }
      allTemplatesList.push(template);
    });
    setAllTemplates(allTemplatesList);
    setSelectedTemplates(allTemplatesList);
    setDataMap(myMap);
  };

  const handleAccordionChange = (panel: string | boolean) => (
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
    if (panel === 'panel-all') {
      setSelectedTemplates(allTemplates);
    }
  };

  async function handleEmptyTemplateClick() {
    const baseImagePath =
      process.env.REACT_APP_URL || 'https://pages.leadlovers.com';

    const templateResponse = await fetch(
      '/resources/templates/base-blank.json'
    );
    let templateText = await templateResponse.text();
    templateText = templateText.replace(/#base_url#/g, baseImagePath);
    const json = JSON.parse(templateText);
    onSelect(json);
  }

  const handleClose = async () => {
    await EditorService.close(pageId, sso);
    // Only works if it's a target=_blank window
    window.close();
  };

  useEffect(() => {
    const fetchData = async () => {
      const c = await TemplatesService.getCategories({ sso });
      const t = await TemplatesService.getTemplates({ sso });
      buildMap(c, t);
    };
    fetchData().catch(console.error);
  }, [sso]);

  let dataMapKeys: number[] = [];

  if (dataMap) dataMapKeys = Array.from(dataMap.keys());

  return (
    <Container>
      <Header>
        <div style={{ display: 'flex' }}>
          <CloseIcon onClick={handleClose} />
          <Title>Selecione um Modelo</Title>
        </div>
        <div>
          <a
            href="https://leadlovers.com.br/modelos-pagina"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sugerir modelo
          </a>
        </div>
      </Header>
      <Sidenav>
        {dataMap &&
          Array.from(dataMap.values()).map((category, i) => {
            const id = dataMapKeys[i] ?? i;
            return (
              <Accordion
                key={id}
                expanded={expanded === `panel${id}`}
                onChange={handleAccordionChange(`panel${id}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span>{category.name}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    {Array.from(category.items.keys()).map((subcategory, j) => (
                      <li
                        key={id + j}
                        className={
                          selectedSubcategory === subcategory ? 'selected' : ''
                        }
                      >
                        <div
                          onClick={() => {
                            setSelectedSubcategory(subcategory);
                            setSelectedTemplates(
                              category.items?.get(subcategory) ?? []
                            );
                          }}
                          role="button"
                          tabIndex={j}
                        >
                          <span>{subcategory}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            );
          })}
        {dataMap && (
          <Accordion
            expanded={expanded === `panel-all`}
            onChange={handleAccordionChange(`panel-all`)}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span className="label-show-all">Ver todos</span>
            </AccordionSummary>
          </Accordion>
        )}
      </Sidenav>
      <Content>
        {dataMap && (
          <EmptyTemplate onClick={handleEmptyTemplateClick}>
            <Fab aria-label="add">
              <AddIcon />
            </Fab>
            <p>Nova página em branco</p>
          </EmptyTemplate>
        )}
        {selectedTemplates.map(template => (
          <PageTemplate
            key={template.id}
            template={template}
            onClick={onSelect}
          />
        ))}
      </Content>
    </Container>
  );
};

export default TemplateSelector;
