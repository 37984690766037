import React from 'react';
import { Route } from 'react-router-dom';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  path: string;
  isPrivate?: boolean;
}

const RouteWrapper = ({ component: Component, path = '', ...rest }: IProps) => {
  return (
    <Route {...rest} path={path} render={props => <Component {...props} />} />
  );
};

export default RouteWrapper;
