import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

Sentry.init({
  dsn:
    'https://f3f920a7b0224a3b97bc8f33293cea6f@o418414.ingest.sentry.io/4505522966298624',
  // Performance Monitoring
  tracesSampleRate: 1.0,
  beforeSend: event => {
    // Conditional to prevent sentry to sent all errors... With this, only specified errors are been sendled.
    if (!event.transaction) {
      return null;
    }
    return event;
  },
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onerror: false,
      onunhandledrejection: false
    })
  ]
});

ReactDOM.render(<App />, document.getElementById('root'));
