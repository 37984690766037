import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { createReducer } from 'reduxsauce';

import {
  ILoadFailureAction,
  ILoadSuccessAction,
  ISSOState,
  SSOTypes
} from './types';

const INITIAL_STATE: ISSOState = {
  type: 'bearer',
  token: '',
  refreshToken: '',
  name: '',
  email: '',
  errorMessage: '',
  valid: false
};

const loadFailure: Reducer = (
  state = INITIAL_STATE,
  action: ILoadFailureAction
) =>
  produce(state, (draft: Draft<ISSOState>) => {
    draft.errorMessage = action.payload?.errorMessage ?? '';
    draft.valid = false;
  });

const loadRequest: Reducer = (state = INITIAL_STATE) =>
  produce(state, (draft: Draft<ISSOState>) => {
    draft.valid = false;
  });

const loadSuccess: Reducer = (
  state = INITIAL_STATE,
  action: ILoadSuccessAction
) =>
  produce(state, (draft: Draft<ISSOState>) => {
    draft.type = action.payload?.type ?? draft.type;
    draft.token = action.payload?.token ?? draft.token;
    draft.refreshToken = action.payload?.refreshToken ?? draft.refreshToken;
    draft.email = action.payload?.email ?? draft.email;
    draft.name = action.payload?.name ?? draft.name;
    draft.errorMessage = '';
    draft.valid = true;
  });

export default createReducer(INITIAL_STATE, {
  [SSOTypes.LOAD_FAILURE]: loadFailure,
  [SSOTypes.LOAD_REQUEST]: loadRequest,
  [SSOTypes.LOAD_SUCCESS]: loadSuccess
});
