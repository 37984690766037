import React, { useState, useEffect } from 'react';

import MatrixField, { IFieldContent } from './Field';
import { Container, FieldList } from './styles';

import MatrixOrganizer from '~/components/MatrixOrganizer';
import { IMatrixContent } from '~/models/matrix.model';

const Matrix = () => {
  const [content, setContent] = useState<IFieldContent[]>([]);
  const [data, setData] = useState<IMatrixContent[]>([]);
  const [layout, setLayout] = useState<string[][]>([]);
  const [newLayout, setNewLayout] = useState<string[][]>([]);

  const handleAdd = (changedLayout: string[][]): void => {
    setNewLayout(changedLayout);
  };

  const handleAddField = (item: IFieldContent) => {
    const updatedLayout: string[][] = JSON.parse(JSON.stringify(newLayout));
    const layoutX = updatedLayout.findIndex(x => x.some(y => y === 'temp'));
    const layoutY = updatedLayout[layoutX].findIndex(y => y === 'temp');
    const rowSize = updatedLayout[layoutX].length;

    if (
      (item.type === 'checkbox' || item.type === 'radio') &&
      (item.options?.length ?? 0) > 1 &&
      rowSize > 1
    ) {
      updatedLayout[layoutX].splice(layoutY, 1);
      updatedLayout.splice(layoutX + 1, 0, [item.key]);
    } else {
      updatedLayout[layoutX].splice(layoutY, 1, item.key);
    }

    setNewLayout([]);
    setLayout(updatedLayout);
    setContent(currentContent =>
      currentContent.map(x => {
        if (x.key === item.key) {
          x.removeFromLayout = false;
        }
        return x;
      })
    );
  };

  const handleChange = (updatedLayout: string[][], key?: string): void => {
    setLayout(updatedLayout);
    if (key) {
      setContent(currentContent =>
        currentContent.map(x => {
          if (x.key === key) {
            x.removeFromLayout = true;
          }
          return x;
        })
      );
    }
  };

  useEffect(() => {
    setData(
      content
        .filter(x => !x.removeFromLayout)
        .map(item => ({
          id: item.key,
          canBeRemoved: item.canBeRemovedFromLayout,
          canShareRow: item.type !== 'checkbox' && item.type !== 'radio',
          content: <MatrixField content={item} />
        }))
    );
    // eslint-disable-next-line
  }, [content]);

  useEffect(() => {
    setLayout([['email'], ['name', 'whatsapp'], ['message']]);
    setContent([
      {
        key: 'email',
        name: 'email',
        type: 'email',
        label: 'Seu e-mail',
        placeholder: 'Informe o seu e-mail',
        required: true,
        removeFromLayout: false,
        canBeRemovedFromLayout: false
      },
      {
        key: 'name',
        name: 'name',
        type: 'text',
        label: 'Seu nome completo',
        placeholder: 'Informe seu nome completo',
        required: true,
        removeFromLayout: false,
        canBeRemovedFromLayout: true
      },
      {
        key: 'whatsapp',
        name: 'phone',
        type: 'text',
        label: 'Seu WhatsApp',
        placeholder: 'Informe o número do seu whatsapp',
        required: true,
        removeFromLayout: false,
        canBeRemovedFromLayout: true
      },
      {
        key: 'company',
        name: 'company',
        type: 'text',
        label: 'Sua empresa',
        placeholder: 'Informe o nome da sua empresa',
        required: true,
        removeFromLayout: true,
        canBeRemovedFromLayout: true
      },
      {
        key: 'city',
        name: 'city',
        type: 'text',
        label: 'Sua cidade',
        placeholder: 'Informe sua cidade',
        required: true,
        removeFromLayout: true,
        canBeRemovedFromLayout: true
      },
      {
        key: 'state',
        name: 'state',
        type: 'text',
        label: 'A sigla do estado',
        placeholder: 'Informe a sigla do seu estado',
        required: true,
        removeFromLayout: true,
        canBeRemovedFromLayout: true
      },
      {
        key: 'message',
        name: 'message',
        type: 'textarea',
        label: 'Qual sua dúvida?',
        placeholder: 'Digite sua dúvida...',
        required: false,
        removeFromLayout: false,
        canBeRemovedFromLayout: true,
        cols: 5
      },
      {
        key: 'weekDays',
        name: 'weekDays',
        type: 'checkbox',
        label: 'Selecione os dias da semana',
        required: false,
        removeFromLayout: true,
        canBeRemovedFromLayout: true,
        options: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado'
        ]
      }
    ]);
  }, []);

  return (
    <Container>
      {!newLayout.length && (
        <MatrixOrganizer
          layout={layout}
          data={data}
          handleAdd={handleAdd}
          handleChange={handleChange}
          hasFields={content.some(x => !!x.removeFromLayout)}
        />
      )}
      {!!newLayout.length && (
        <>
          <h1>Choose a new field</h1>
          <FieldList>
            {content
              .filter(x => !!x.removeFromLayout)
              .map(item => (
                <button
                  key={item.key}
                  type="button"
                  onClick={() => handleAddField(item)}
                >
                  {item.name}
                </button>
              ))}
          </FieldList>
        </>
      )}
    </Container>
  );
};

export default Matrix;
