import { FormStructure, FormFields, FormField } from '@mailupinc/bee-plugin';

import leadlovers from '~/services/leadlovers';
import { convertToKey } from '~/utils';

interface ILeadLoversResponse<T> {
  Items: T[];
  StatusCode: number;
  Message: string;
  Exception: string;
}

interface ILeadLoversDynamicField {
  Id: number;
  Type: string;
  Status: string;
  Name: string;
  Label: string;
  Tag: string;
  RegisterDate: Date;
}

interface ILeadLoversDynamicFieldOption {
  Id: number;
  DynamicFieldId: number;
  Status: string;
  Value: string;
  Score: number;
  RegisterDate: Date;
}

type FieldType =
  | 'email'
  | 'radio'
  | 'checkbox'
  | 'select'
  | 'tel'
  | 'textarea'
  | 'text';

export type NativeFieldType = FieldType | 'date' | 'cpf' | 'cnpj' | 'cpf_cnpj';

const fieldsWithOptions: FieldType[] = ['checkbox', 'radio', 'select'];

class FormsService {
  private convertFields = (data: Map<string, FormField>): FormFields => {
    const obj: FormFields = {};
    data.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  };

  public async getDynamicForm(
    token: string
  ): Promise<FormStructure | undefined> {
    try {
      const response = await leadlovers.get<
        ILeadLoversResponse<ILeadLoversDynamicField>
      >(`DynamicFields?token=${token}`);
      if (response.status === 200) {
        const firstEmailField = response.data.Items.find(
          x => x.Type === 'E-mail'
        )?.Id;
        const array = await Promise.all(
          response.data.Items.map(
            async (i): Promise<[string, FormField]> => {
              const fieldType = this.mapType(i.Type);
              const options: string[] = fieldsWithOptions.includes(fieldType)
                ? await this.getDynamicFieldOptions(i.Id, token)
                : [];
              return [
                convertToKey(`${i.Name} ${i.Id}`),
                {
                  type: fieldType,
                  label: i.Name,
                  removeFromLayout: i.Id !== firstEmailField,
                  canBeRemovedFromLayout: i.Id !== firstEmailField,
                  options,
                  attributes: {
                    key: convertToKey(i.Name),
                    name: `llfield${i.Id}`,
                    cols: fieldType === 'textarea' ? 3 : undefined,
                    placeholder: i.Label,
                    required: fieldType === 'email',
                    itemtype: this.mapNativeType(i.Type)
                  }
                }
              ];
            }
          )
        );

        const map = new Map(array);
        const fields = this.convertFields(map);
        const layout: string[][] = response.data.Items.map(item => [
          convertToKey(`${item.Name} ${item.Id}`)
        ]);

        fields.button = {
          type: 'submit',
          label: '',
          canBeRemovedFromLayout: false,
          attributes: {
            value: 'ENVIAR',
            name: 'submit_button'
          }
        };

        return {
          fields,
          layout,
          attributes: {
            action: '/capture',
            method: 'post',
            target: '_self',
            autocomplete: 'off'
          },
          title: 'dynamic',
          description: 'LeadLovers form with dynamic fields'
        };
      }
    } catch (err) {
      console.error(err);
    }
    return undefined;
  }

  public async getDynamicFieldOptions(
    fieldId: number,
    token: string
  ): Promise<string[]> {
    try {
      const response = await leadlovers.get<
        ILeadLoversResponse<ILeadLoversDynamicFieldOption>
      >(`DynamicFieldOptions/${fieldId}?token=${token}`);
      if (response.status === 200) {
        return response.data.Items.map(x => x.Value);
      }
    } catch (err) {
      console.error('DynamicFieldOptions ERROR:', err);
    }
    return [];
  }

  private mapType(type: string): FieldType {
    switch (type) {
      case 'E-mail':
        return 'email';
      case 'Escolha Única (radio button)':
        return 'radio';
      case 'Multipla Escolha (checkbox)':
        return 'checkbox';
      case 'Seleção':
        return 'select';
      case 'Telefone':
        return 'tel';
      case 'Texto Grande':
        return 'textarea';
      default:
        return 'text';
    }
  }

  private mapNativeType(type: string): NativeFieldType {
    switch (type) {
      case 'E-mail':
        return 'email';
      case 'Escolha Única (radio button)':
        return 'radio';
      case 'Multipla Escolha (checkbox)':
        return 'checkbox';
      case 'Seleção':
        return 'select';
      case 'Telefone':
        return 'tel';
      case 'Texto Grande':
        return 'textarea';
      case 'Data':
        return 'date';
      case 'CPF':
        return 'cpf';
      case 'CNPJ':
        return 'cnpj';
      case 'CPF/CNPJ':
        return 'cpf_cnpj';
      default:
        return 'text';
    }
  }
}

export default new FormsService();
