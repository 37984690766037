import React, { useCallback, useState, useEffect } from 'react';

import {
  Button,
  createMuiTheme,
  Divider,
  ThemeProvider,
  ButtonGroup
} from '@material-ui/core';
import { MenuOpen } from '@material-ui/icons';
import PreviewIconOn from '@material-ui/icons/DesktopWindows';
import PreviewIconOff from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import StructureIconOn from '@material-ui/icons/Visibility';
import StructureIconOff from '@material-ui/icons/VisibilityOff';

import { Container } from './styles';

import ThemeColors from '~/styles/theme-colors';

const MENU_POSITION_LOCAL_STORAGE_KEY = 'leadlovers-bee-editor_menu-position';

interface IToolbarProps {
  togglePreview: () => void;
  toggleStructure: () => void;
  onSave: (close: boolean) => void;
  onCleanTemplate: () => void;
  onChangeMenuPosition: (direction: 'left' | 'right') => void;
}

const Toolbar: React.FC<IToolbarProps> = ({
  togglePreview,
  toggleStructure,
  onSave,
  onCleanTemplate,
  onChangeMenuPosition
}: IToolbarProps) => {
  const [preview, setPreview] = useState(false);
  const [structure, setStructure] = useState(false);
  const [menuPosition, setMenuPosition] = useState<'left' | 'right' | null>(
    null
  );

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: ThemeColors.primaryColor[50],
        main: ThemeColors.primaryColor[100],
        dark: ThemeColors.primaryColor[200],
        contrastText: '#fff'
      },
      secondary: {
        light: '#fff',
        main: '#fafafa',
        dark: '#f5f5f5',
        contrastText: ThemeColors.primaryColor[100]
      }
    }
  });

  const handleChangeMenuPosition = useCallback(
    (val: 'left' | 'right') => {
      localStorage.setItem(MENU_POSITION_LOCAL_STORAGE_KEY, val);
      setMenuPosition(val);
      onChangeMenuPosition(val);
    },
    [onChangeMenuPosition]
  );

  const handleTogglePreview = useCallback(() => {
    setPreview(current => !current);
    togglePreview();
  }, [togglePreview]);

  const handleToggleStructure = useCallback(() => {
    setStructure(current => !current);
    toggleStructure();
  }, [toggleStructure]);

  useEffect(() => {
    const pos =
      (localStorage.getItem(MENU_POSITION_LOCAL_STORAGE_KEY)?.toString() as
        | 'left'
        | 'right') ?? 'left';
    handleChangeMenuPosition(pos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div>
          {!preview && (
            <Button
              color="secondary"
              size="small"
              onClick={() => onCleanTemplate()}
            >
              Voltar
            </Button>
          )}
          <Divider orientation="vertical" variant="middle" flexItem light />
          <Button
            color="secondary"
            size="small"
            startIcon={preview ? <PreviewIconOff /> : <PreviewIconOn />}
            onClick={handleTogglePreview}
          >
            {preview ? 'Editar' : 'Visualizar'}
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem light />
          <Button
            color="secondary"
            size="small"
            startIcon={structure ? <StructureIconOff /> : <StructureIconOn />}
            onClick={handleToggleStructure}
          >
            {structure ? 'Ocultar estrutura' : 'Exibir estrutura'}
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem light />
          <span className="menu-direction-span">
            <MenuOpen /> Menu:
          </span>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              onClick={() => handleChangeMenuPosition('left')}
              variant="contained"
              size="small"
              className={menuPosition === 'left' ? 'selected' : ''}
            >
              Esquerda
            </Button>
            <Button
              onClick={() => handleChangeMenuPosition('right')}
              variant="contained"
              size="small"
              className={menuPosition === 'right' ? 'selected' : ''}
            >
              Direita
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <Button
            color="secondary"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => onSave(false)}
          >
            Salvar
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem light />
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSave(true)}
            id="btn-save-and-close"
          >
            Salvar & Fechar
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Toolbar;
