/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { PulseLoader } from 'react-spinners';

import {
  FormStructure,
  FormFields,
  FormField,
  FormFieldOption,
  FormLayout
} from '@mailupinc/bee-plugin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import Field, { FieldType } from './Field';
import {
  Column,
  DialogContent,
  EndRow,
  FieldList,
  Form,
  FormRow,
  FormSolidColumn,
  Loading,
  Row
} from './styles';

import { IUserData, FormType } from '~/components/FormSelector/context';
import MatrixOrganizer from '~/components/MatrixOrganizer';
import { IExternalContent } from '~/models/beefree.model';
import { IFieldModel } from '~/models/field.model';
import { IMatrixContent } from '~/models/matrix.model';
import { structure as StaticStructure } from '~/resources/form.json';
import FormsService from '~/services/forms';
import RowsService, { IRowData } from '~/services/rows';
import ThemeColors from '~/styles/theme-colors';
import { convertToKey, convertToOldKey } from '~/utils';

export interface IFormSelectorProps {
  open: boolean;
  returnExternalContent?: boolean;
  structure?: FormStructure;
  user?: IUserData;
  resolve: (json: unknown) => void;
  reject: () => void;
}

export interface ILayoutIndex {
  row: number;
  column: number;
}

const FormSelectorDialog = ({
  open,
  returnExternalContent,
  structure,
  user,
  resolve,
  reject
}: IFormSelectorProps) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: ThemeColors.primaryColor[50],
        main: ThemeColors.primaryColor[100],
        dark: ThemeColors.primaryColor[200],
        contrastText: '#fff'
      },
      secondary: {
        light: '#787d80',
        main: '#515658',
        dark: '#383e40',
        contrastText: '#fff'
      }
    }
  });

  const [baseStructure, setBaseStructure] = useState<FormStructure>();
  const [baseStructureLoaded, setBaseStructureLoaded] = useState(false);
  const [customFormName, setCustomFormName] = useState<string>('');
  const [customFormNameError, setCustomFormNameError] = useState<string>('');
  const [customRow, setCustomRow] = useState<IRowData>();
  const [dynamicStructure, setDynamicStructure] = useState<FormStructure>();
  const [dynamicStructureLoaded, setDynamicStructureLoaded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [fields, setFields] = useState<IFieldModel[]>([]);
  const [formType, setFormType] = useState<FormType>('static');
  const [loading, setLoading] = useState(true);
  const [matrixData, setMatrixData] = useState<IMatrixContent[]>([]);
  const [matrixLayout, setMatrixLayout] = useState<string[][]>([]);
  const [newLayout, setNewLayout] = useState<string[][]>([]);
  const [saving, setSaving] = useState(false);
  const [submitButton, setSubmitButton] = useState<IFieldModel>(
    {} as IFieldModel
  );

  const addExternalContent = (content: IExternalContent) => {
    let userContent: IExternalContent[] = [];
    const key = `external-content:${user?.id ?? 0}`;
    const storageData = localStorage.getItem(key);
    if (storageData) {
      userContent = JSON.parse(storageData);
    }

    if (!userContent.some(x => x.name === content.name)) {
      userContent.push(content);
      localStorage.setItem(key, JSON.stringify(userContent));
    }
  };

  const getFieldColumns = (
    key: string,
    field: FormField,
    layout: string[][]
  ): number => {
    if (field.type === 'label') {
      const fieldStructure = Object.entries<FormField>(
        formType === 'static'
          ? StaticStructure.fields
          : dynamicStructure!.fields
      ).find(
        ([_key, _data]) =>
          _key === key ||
          _data.attributes?.key === key ||
          _data.attributes?.name === key
      );
      if (fieldStructure && fieldStructure[1].options?.length) {
        const fieldKeyA = fieldStructure[0].replace(/_/g, '');
        const fieldKeyB =
          fieldStructure[1].attributes?.key?.replace(/_/g, '') ?? '';
        const sampleA = convertToKey(fieldStructure[1].options[0] as string);
        const sampleB = convertToOldKey(fieldStructure[1].options[0] as string);
        const sampleC = `${fieldKeyB}${convertToKey(
          fieldStructure[1].options[0] as string
        )}`;
        const sampleD = `${fieldKeyA}${convertToKey(
          fieldStructure[1].options[0] as string
        )}`;
        const rowIndex = layout.findIndex(row =>
          row.some(
            col =>
              col === sampleA ||
              col === sampleB ||
              col === sampleC ||
              col === sampleD
          )
        );
        if (rowIndex >= 0) {
          return layout[rowIndex].length;
        }
      }
    }

    return 1;
  };

  const getFieldKey = (key: string): string => {
    const fieldStructure = Object.entries<FormField>(
      formType === 'static' ? StaticStructure.fields : dynamicStructure!.fields
    ).find(([_key, _data]) => _key === key || _data.attributes?.name === key);
    if (fieldStructure) {
      return fieldStructure[0];
    }

    return key;
  };

  const getFieldName = (key: string): string => {
    const fieldStructure = Object.entries<FormField>(
      formType === 'static' ? StaticStructure.fields : dynamicStructure!.fields
    ).find(([_key, _data]) => _key === key || _data.attributes?.name === key);
    if (fieldStructure) {
      return fieldStructure[1].attributes?.name ?? 'unkown';
    }

    return 'unkown';
  };

  const getFieldOptions = (key: string, field: FormField): string[] => {
    if (field.type === 'label') {
      const fieldStructure = Object.entries<FormField>(
        formType === 'static'
          ? StaticStructure.fields
          : dynamicStructure!.fields
      ).find(
        ([_key, _data]) =>
          _key === key ||
          _data.attributes?.key === key ||
          _data.attributes?.name === key
      );
      if (fieldStructure && fieldStructure[1].options?.length) {
        return fieldStructure[1].options as string[];
      }
      return [];
    }

    return field.options &&
      (field.options as FormFieldOption[]).length > 0 &&
      (field.options as FormFieldOption[])[0].label
      ? (field.options as FormFieldOption[])
          .filter(opt => !!opt.value)
          .map(opt => opt.value)
      : (field.options as string[]) ?? [];
  };

  const getFieldRequired = (
    name?: string,
    required?: boolean,
    requiredFields?: FormField
  ) => {
    if (!required && name && requiredFields) {
      const _fields = requiredFields.attributes?.value?.split(',') ?? [];
      if (_fields.length) {
        return _fields.includes(name);
      }
    }
    return required ?? false;
  };

  const getFieldType = (key: string, field: FormField): string => {
    if (
      (field.type === 'checkbox' || field.type === 'radio') &&
      !field.options?.length
    ) {
      return 'unkown';
    }
    if (field.type === 'label') {
      const fieldStructure = Object.entries<FormField>(
        formType === 'static'
          ? StaticStructure.fields
          : dynamicStructure!.fields
      ).find(([_key, _data]) => _key === key || _data.attributes?.name === key);
      if (fieldStructure) {
        return fieldStructure[1].type;
      }
    }
    return field.type;
  };

  const handleAdd = (changedLayout: string[][]): void => {
    setNewLayout(changedLayout);
  };

  const handleAddField = (item: IFieldModel) => {
    const updatedLayout: string[][] = JSON.parse(JSON.stringify(newLayout));
    const layoutX = updatedLayout.findIndex(x => x.some(y => y === 'temp'));
    const layoutY = updatedLayout[layoutX].findIndex(y => y === 'temp');
    const rowSize = updatedLayout[layoutX].length;

    if (
      (item.type === 'checkbox' || item.type === 'radio') &&
      (item.options?.length ?? 0) > 1 &&
      rowSize > 1
    ) {
      const after = rowSize === 1 || layoutY > 0;
      updatedLayout[layoutX].splice(layoutY, 1);
      updatedLayout.splice(layoutX + (after ? 1 : 0), 0, [item.key]);
    } else {
      updatedLayout[layoutX].splice(layoutY, 1, item.key);
    }

    setNewLayout([]);
    setMatrixLayout(updatedLayout);

    setFields(currentFields =>
      currentFields
        .map(x => {
          x.isNew = x.key === item.key;
          if (x.key === item.key) {
            x.removeFromLayout = false;
          }
          return x;
        })
    );
  };

  const handleCancelButton = () => {
    if (newLayout.length) {
      setNewLayout([]);
    } else {
      reject();
    }
  };

  const handleCancelCustomForm = () => {
    setCustomFormName('');
    setSaving(false);
  };

  const handleChange = (key: string, type: FieldType, value: any, isHidden?: boolean): void => {
    if (isHidden || (typeof value !== 'undefined' && value !== '' && baseStructureLoaded)) {
      if (submitButton.key === key) {
        setSubmitButton(current => ({ ...current, value }));
      } else {
        const clonedFields: IFieldModel[] = JSON.parse(JSON.stringify(fields));
        const fieldIndex = clonedFields.findIndex(f => f.key === key);
        if (fieldIndex >= 0) {
          switch (type) {
            case 'cols':
              clonedFields[fieldIndex].cols = value;
              break;
            case 'label':
              clonedFields[fieldIndex].label = value;
              break;
            case 'placeholder':
              clonedFields[fieldIndex].placeholder = value;
              break;
            case 'required':
              clonedFields[fieldIndex].required = value;
              break;
            case 'value':
              clonedFields[fieldIndex].value = value;
              break;
            default:
              break;
          }

          setFields(clonedFields);
        }
      }
    }
  };

  const handleCustomFormNameChange = (value: string) => {
    setCustomFormName(value);
    setCustomFormNameError('');
    if (!value) {
      setCustomFormNameError('Dê um nome ao formulário');
    } else if (!/^([0-9a-zÀ-ÿ-+,._ ])+$/gi.test(value)) {
      setCustomFormNameError('Nome inválido pois possui caracteres especiais');
    }
  };

  const handleFormType = (newFormType: FormType) => {
    setFormType(newFormType);
    setNewLayout([]);
    if (
      structure &&
      structure.fields &&
      structure.layout.length &&
      structure.title === newFormType
    ) {
      setBaseStructure(structure);
      setFormType((structure?.title || 'static') as FormType);
    } else if (newFormType === 'dynamic') {
      const clonedDynamicStructure = JSON.parse(
        JSON.stringify(dynamicStructure)
      );
      setBaseStructure(clonedDynamicStructure);
    } else {
      setBaseStructure(StaticStructure);
    }
    setBaseStructureLoaded(false);
  };

  const handleLayoutChange = (
    updatedLayout: string[][],
    key?: string
  ): void => {
    setMatrixLayout(updatedLayout);
    if (key) {
      setFields(currentFields =>
        currentFields.map(x => {
          if (x.key === key) {
            x.removeFromLayout = true;
          }
          // if (!x.label && x.key === 'sck') {
          //   x.label = 'Identificador SCK'
          // }
          return x;
        })
      );
    }
  };

  const handleSave = async () => {
    if (returnExternalContent) {
      setSaving(true);
      setCustomRow({
        formType,
        fields: [...fields.filter(x => !x.removeFromLayout), submitButton],
        layout: [...matrixLayout, [submitButton.key]],
        name: customFormName,
        owner: user?.id ?? 0
      });
    } else {
      const clonedLayout: string[][] = JSON.parse(JSON.stringify(matrixLayout));
      const newStructure: FormStructure = JSON.parse(
        JSON.stringify(baseStructure)
      );
      const newFields: FormFields = {};

      if (fields.length) {
        const typesWithOptions = ['checkbox', 'radio', 'select'];
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
          // dont add fields of multiple options if it has no options
          if (typesWithOptions.includes(field.type) && !field.options?.length) {
            // eslint-disable-next-line no-continue
            continue;
          }

          // add to layout if not in matrix
          if (field.removeFromLayout) {
            clonedLayout.push([field.key]);
          }

          if (field.type === 'checkbox' || field.type === 'radio') {
            if (field.options?.length) {
              // add to fields list
              newFields[field.key] = {
                type: 'label',
                label: field.label ?? 'Select an option',
                canBeRemovedFromLayout: field.canBeRemovedFromLayout,
                removeFromLayout: field.removeFromLayout ?? true
              };
              let index = 1;
              let checkboxLayout: string[] = [];
              const rowIndex = clonedLayout.findIndex(row =>
                row.some(col => col === field.key)
              );
              // add options to fields list and layout
              field.options.forEach(opt => {
                const optKey = convertToKey(`${field.key}_${opt}`);
                newFields[optKey] = {
                  type: field.type,
                  label: opt,
                  canBeRemovedFromLayout: field.canBeRemovedFromLayout,
                  removeFromLayout: field.removeFromLayout ?? true,
                  attributes: {
                    name: field.name,
                    value: opt,
                    title: opt
                  }
                };
                checkboxLayout.push(optKey);
                if (checkboxLayout.length === (field.cols ?? 1)) {
                  clonedLayout.splice(rowIndex + index, 0, [...checkboxLayout]);
                  checkboxLayout = [];
                  index += 1;
                }
              });
              if (checkboxLayout.length) {
                clonedLayout.splice(rowIndex + index, 0, [...checkboxLayout]);
              }
            }
          } else {
            const data: FormField = {
              type: field.type,
              label: field.label,
              removeFromLayout: field.removeFromLayout ?? false,
              canBeRemovedFromLayout: field.canBeRemovedFromLayout,
              attributes: {
                name: field.name,
                required: field.required,
                placeholder: field.placeholder,
                cols: field.cols,
                value: field.value,
                itemtype: field.fieldType
              }
            };

            if (typeof field.label === 'undefined') {
              delete data.label;
            }
            if (!field.required) {
              delete data.attributes?.required;
            }
            if (typeof field.placeholder === 'undefined') {
              delete data.attributes?.placeholder;
            }
            if (
              typeof field.cols === 'undefined' ||
              field.type !== 'textarea'
            ) {
              delete data.attributes?.cols;
            }
            if (typeof field.value === 'undefined') {
              delete data.attributes?.value;
            }

            if (field.type === 'select' && field.options?.length) {
              data.options = field.options.map(opt => ({
                type: 'option',
                value: opt,
                label: opt
              }));
              if (field.required) {
                data.options.unshift({
                  type: 'option',
                  value: '',
                  label: 'Selecione'
                });
              }
              delete data.attributes?.placeholder;
            }

            newFields[field.key] = data;
          }
        }

        // add hidden field with required fields limited by bee structure
        // which are checkbox and radio
        const requiredFields = fields
          .filter(
            field =>
              (field.type === 'radio' || field.type === 'checkbox') &&
              field.required
          )
          .map(field => field.name ?? '');
        if (requiredFields.length > 0) {
          clonedLayout.unshift(['requiredFields']);
          newFields.requiredFields = {
            type: 'hidden',
            removeFromLayout: false,
            canBeRemovedFromLayout: false,
            attributes: {
              id: 'requiredFields',
              name: 'requiredFields',
              value: requiredFields.join(',')
            }
          };
        }

        // add form type hidden field
        clonedLayout.unshift(['formType']);
        newFields.formType = {
          type: 'hidden',
          removeFromLayout: false,
          canBeRemovedFromLayout: false,
          attributes: {
            id: 'formType',
            name: 'formType',
            value: formType
          }
        };

        // add submit button
        clonedLayout.push([submitButton?.key ?? '']);
        newFields[submitButton!.key] = {
          type: submitButton!.type,
          label: submitButton!.label,
          canBeRemovedFromLayout: submitButton!.canBeRemovedFromLayout,
          attributes: {
            name: submitButton!.name,
            value: submitButton!.value
          }
        };

        const indexToRemove = clonedLayout.findIndex(item => item.includes('sck'));

        if (indexToRemove !== -1) {
          clonedLayout.splice(indexToRemove, 1);
          clonedLayout.unshift(['sck']);
        }

        newStructure.fields = newFields;
        newStructure.layout = clonedLayout
      }

      resolve({ structure: newStructure });
    }
  };

  const handleSaveCustomForm = async () => {
    setSaving(false);
    if (customRow) {
      setLoading(true);
      const apiURL = process.env.REACT_APP_API_URL;
      const response = await RowsService.save({
        ...customRow,
        name: customFormName
      });
      if (response && response.key) {
        const content: IExternalContent = {
          name:
            formType === 'static'
              ? `Formulário Estático: ${customFormName}`
              : `Formulário Dinâmico: ${customFormName}`,
          value: `${apiURL}/rows/${response.key}`
        };
        addExternalContent(content);
        setSaving(false);
        setLoading(false);
        resolve(content);
      } else {
        reject();
      }
    }
  };

  const loadBaseStructure = (_structure: FormStructure) => {
    const _baseStructure =
      formType === 'dynamic' && editing && dynamicStructure
        ? dynamicStructure
        : _structure;

    const { requiredFields } = _structure.fields;
    const mappedFields: IFieldModel[] = Object.entries(_baseStructure.fields)
      .map(([key, field]) => {
        const _field =
          _structure.fields[key] ??
          _structure.fields[field.attributes?.key ?? ''];

        const _type = getFieldType(key, field);
        const _key = _type === 'unkown' ? key : getFieldKey(key);
        const name =
          _type === 'unkown'
            ? 'unkown'
            : field.attributes?.name ?? getFieldName(key);
        const options = _type === 'unkown' ? [] : getFieldOptions(key, field);
        const placeholder =
          field.type === 'label' || field.type === 'select'
            ? field.label
            : _field?.attributes?.placeholder ?? field.attributes?.placeholder;
        const required =
          _type === 'unkown'
            ? false
            : getFieldRequired(
                name,
                _field?.attributes?.required ?? field?.attributes?.required,
                requiredFields
              );
        const cols =
          _type === 'unkown'
            ? 0
            : getFieldColumns(key, _field ?? field, _structure.layout);
        const removeFromLayout = _field
          ? _field.removeFromLayout
          : field.removeFromLayout;

        return {
          key: _key,
          type: _type,
          label: field?.label,
          altKey: field.attributes?.key,
          name,
          options,
          placeholder,
          required,
          cols,
          value: field.attributes?.value,
          removeFromLayout,
          canBeRemovedFromLayout:
            _field?.canBeRemovedFromLayout ??
            field.canBeRemovedFromLayout ??
            true,
          isExternalContent: !!returnExternalContent,
          fieldType: field.attributes?.itemtype
        } as IFieldModel;
      })
      .filter(field => field.type !== 'unkown');

    const submitIndex = mappedFields.findIndex(x => x.type === 'submit');
    const mappedSubmitButton = mappedFields.splice(submitIndex, 1)[0];
    const mappedLayout = (_structure.layout as Array<string[] | FormLayout[]>)
      .map<string[]>(x =>
        (x as Array<string | FormLayout>)
          .filter(y => {
            if ((y as FormLayout).fields) {
              return false;
            }
            return mappedFields.some(
              f =>
                (f.key === y || f.altKey === y || f.name === y) &&
                !f.removeFromLayout
            );
          })
          .map(m => {
            if ((m as FormLayout).fields) {
              const group = m as FormLayout;
              return group.legend ?? group.fields[0][0];
            }
            const fieldRef = mappedFields.find(
              field => field.key === m || field.altKey === m || field.name === m
            );
            if (fieldRef) {
              return fieldRef.key;
            }
            return m as string;
          })
      )
      .filter(
        (x: string[]) =>
          x.length > 0 &&
          !x.includes(mappedSubmitButton.key) &&
          !x.includes('formType') &&
          !x.includes('requiredFields')
      );

    setMatrixData([]);
    setFields(mappedFields);
    setSubmitButton(mappedSubmitButton);
    setMatrixLayout(mappedLayout as string[][]);
    setBaseStructureLoaded(true);
  };

  useEffect(() => {
    const activeEmailFields = fields.filter(
      x => !x.removeFromLayout && x.type === 'email'
    ).length;

    setMatrixData(
      fields
        .filter(x => !x.removeFromLayout)
        .map(item => ({
          id: item.key,
          focused: item.isNew,
          canBeRemoved:
            item.type === 'email'
              ? activeEmailFields > 1
              : item.type === 'hidden' ? true : item.canBeRemovedFromLayout,
          canShareRow: item.type !== 'checkbox' && item.type !== 'radio',
          content: <Field content={item} onChange={handleChange} />
        }))
    );
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    if (baseStructure && dynamicStructureLoaded && !baseStructureLoaded) {
      loadBaseStructure(baseStructure);
    }
    // eslint-disable-next-line
  }, [baseStructure, dynamicStructureLoaded]);

  useEffect(() => {
    setBaseStructureLoaded(false);
    if (structure && structure.fields && structure.layout.length) {
      setFormType((structure?.title || 'static') as FormType);
      setBaseStructure(structure);
      setEditing(true);
    } else {
      setFormType('static');
      setNewLayout([]);
      setFields([]);
      setSubmitButton({} as IFieldModel);
      setBaseStructure(StaticStructure);
      setEditing(false);
      loadBaseStructure(StaticStructure);
    }
    // eslint-disable-next-line
  }, [structure]);

  useEffect(() => {
    async function loadDynamicFields() {
      const form = await FormsService.getDynamicForm(user?.token ?? '');

      if (form) {
        setDynamicStructure({
          ...form,
          fields: {
            ...form.fields,
            "sck": StaticStructure.fields.sck,
          }
        });
      }

      setLoading(false);
      setDynamicStructureLoaded(true);
    }

    if (user && !dynamicStructure) loadDynamicFields();
  }, [user, dynamicStructure]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => resolve(null)}
        onExited={() => reject()}
      >
        {loading && (
          <DialogContent>
            <Row>
              <Column>
                <Loading>
                  <PulseLoader
                    size={22}
                    margin={10}
                    color={ThemeColors.primaryColor[100]}
                  />
                </Loading>
              </Column>
            </Row>
          </DialogContent>
        )}
        {saving && (
          <DialogContent>
            <Row>
              <Column>
                <TextField
                  variant="outlined"
                  label="Nome do formulário personalizado"
                  value={customFormName}
                  error={!!customFormNameError}
                  helperText={customFormNameError}
                  inputProps={{ maxLength: 20 }}
                  tabIndex={-1}
                  onChange={e => handleCustomFormNameChange(e.target.value)}
                />
                <EndRow>
                  <Button
                    variant="contained"
                    color="primary"
                    tabIndex={-1}
                    onClick={() => handleSaveCustomForm()}
                  >
                    Salvar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    tabIndex={-1}
                    onClick={() => handleCancelCustomForm()}
                  >
                    Cancelar
                  </Button>
                </EndRow>
              </Column>
            </Row>
          </DialogContent>
        )}
        {!loading && !saving && (
          <>
            <DialogTitle>Formulários Personalizados</DialogTitle>
            <DialogContent>
              {dynamicStructure && (
                <Row>
                  <Column mright={15}>
                    <DialogContentText>Tipo do formulário:</DialogContentText>
                    <ToggleButtonGroup
                      value={formType}
                      onChange={(_evt, value) => handleFormType(value)}
                      size="small"
                      tabIndex={-1}
                      exclusive
                      aria-label="Form type"
                    >
                      <ToggleButton
                        value="static"
                        aria-label="Static form"
                        tabIndex={-1}
                      >
                        Estático
                      </ToggleButton>
                      <ToggleButton
                        value="dynamic"
                        aria-label="Dynamic form"
                        tabIndex={-1}
                      >
                        Dinâmico
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Column>
                </Row>
              )}
              <Row>
                <Column mtop={dynamicStructure ? 15 : 0}>
                  <DialogContentText>
                    Configurações do formulário:
                  </DialogContentText>
                  <small>
                    Defina configurações como disposição, texto e
                    obrigatoriedade dos campos
                  </small>
                </Column>
              </Row>
              <Row>
                <Column mtop={15}>
                  {!newLayout.length && (
                    <>
                      <MatrixOrganizer
                        layout={matrixLayout}
                        data={matrixData}
                        handleAdd={handleAdd}
                        handleChange={handleLayoutChange}
                        hasFields={fields.some(x => !!x.removeFromLayout)}
                      />
                      <Form>
                        <FormRow>
                          <FormSolidColumn>
                            <Field
                              content={submitButton}
                              onChange={handleChange}
                            />
                          </FormSolidColumn>
                        </FormRow>
                      </Form>
                    </>
                  )}
                  {!!newLayout.length && (
                    <Form>
                      <FormRow>
                        <FormSolidColumn>
                          <p>Selecione um campo para adicionar no formulário</p>
                          <FieldList>
                            {fields
                              .filter(x => !!x.removeFromLayout)
                              .map(item => (
                                <Button
                                  key={item.key}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleAddField(item)}
                                >
                                  {item.key === "sck" ? "Identificador SCK" : item.label}
                                </Button>
                              ))}
                          </FieldList>
                        </FormSolidColumn>
                      </FormRow>
                    </Form>
                  )}
                </Column>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleCancelButton()}
                color="secondary"
                variant="text"
                tabIndex={-1}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                tabIndex={-1}
                onClick={() => handleSave()}
              >
                Salvar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default FormSelectorDialog;
